import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PageSpinner from './PageSpinner'

const PageTransitioner = () => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleScreenChangeStart = () => setLoading(true)
    const handleScreenChangeComplete = () => setLoading(false)

    router.events.on('routeChangeStart', handleScreenChangeStart)
    router.events.on('routeChangeComplete', handleScreenChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleScreenChangeStart)
      router.events.off('routeChangeComplete', handleScreenChangeComplete)
    }
  }, [router.events])

  return <PageSpinner active={loading} showOverlay />
}

export default PageTransitioner
