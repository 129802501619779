import React from 'react'
import classNames from 'classnames'
import { Exclamation } from '@/components/svg'
import Text from '../Text/Text'

export interface Props {
  heading?: string
  text: string
  Icon?: React.FC
  error?: string
  action: JSX.Element
  className?: string
}

const ActionBox: React.FC<Props> = ({
  heading,
  text,
  Icon = Exclamation,
  action,
  error,
  className,
}) => {
  return (
    <div
      className={classNames(
        {
          'rounded border border-gray-8 bg-white p-4 font-whitney text-black':
            true,
        },
        className
      )}
    >
      <div className="flex items-start">
        <div className="mr-2 h-5 w-5">
          <Icon className="text-red" />
        </div>

        <div className="flex-1">
          {heading && (
            <Text as="h3" preset="heading.md" className="mb-2">
              {heading}
            </Text>
          )}
          <Text as="p" preset="body.sm">
            {text}
          </Text>
        </div>
      </div>

      <div className="mt-4">{action}</div>

      {error && <div className="mt-2 text-sm text-red">{error}</div>}
    </div>
  )
}

export default ActionBox
