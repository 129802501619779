import { createContext } from 'react'

export type NotificationType = 'busy' | 'toast' | 'bell'

interface BaseNotification {
  id: string | number
  type: NotificationType
  text: string | JSX.Element
}

export interface BusyNotification extends BaseNotification {
  heading: string | JSX.Element
  type: 'busy'
}

export interface ToastNotification extends BaseNotification {
  type: 'toast'
  variant: 'warning' | 'error'
  text: string | JSX.Element
  actions?: {
    label: string
    onClick: () => void
    variant?: 'primary' | 'secondary'
  }[]
  dismissable?: boolean
  timeout?: number
}

export interface BellNotification extends BaseNotification {
  type: 'bell'
  seen: boolean
  onClick: () => void
}

export type Notification =
  | BusyNotification
  | ToastNotification
  | BellNotification

export type UpdateNotifiction = (
  id: string | number,
  notification: Partial<Notification>
) => void

interface Context {
  notifications: Notification[]
  addNotification: (notification: Notification) => void
  updateNotification: UpdateNotifiction
  removeNotification: (id: string | number) => void
  clearNotifications: (type?: NotificationType) => void
}

const NotificationsContext = createContext<Context>({
  notifications: [],
  addNotification: () => null,
  updateNotification: () => null,
  removeNotification: () => null,
  clearNotifications: () => null,
})

export default NotificationsContext
