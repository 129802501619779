export interface ErrorObject {
  response?: {
    data?: {
      statusDesc?: string
      error?: string
      errors?: string | { [key: string]: string }
      detail?: string
      non_field_errors?: string[]
    }
  }
  data?: {
    errors?: string | { [key: string]: string } | string
  }
  message?: string
}

export interface FieldErrorObject {
  response?: {
    data?: { [key: string]: string | string[] }
  }
}

export interface Options {
  asFormMapping?: boolean
}

export const resolveErrorMessage = (e: ErrorObject, options?: Options) => {
  let errorMessage

  // Sometimes the error response will have an object that maps errors to properties
  // These properties may map to a frontend form. When handling errors in forms, we can
  // pass this `asFormMapping` flag to try to pick off the first error coming back
  // from the server.

  if (
    options?.asFormMapping &&
    e.response?.data &&
    typeof e.response.data === 'object'
  ) {
    // If it's a general error with an `error` key return that
    if (e.response.data.error) return e.response.data.error
    // Else get the first error
    const errors = Object.entries(e.response.data)
    if (errors.length) {
      const [field, error] = errors[0]
      return `${field}: ${Array.isArray(error) ? error.join(', ') : error}`
    }
  }

  try {
    errorMessage =
      e.response?.data?.statusDesc ||
      e.response?.data?.error ||
      e.response?.data?.errors ||
      e.response?.data?.detail ||
      e.response?.data?.non_field_errors?.join(',') ||
      e.data?.errors ||
      e.message ||
      e
  } catch {
    errorMessage = 'An error has occurred'
  } finally {
    if (!errorMessage || typeof errorMessage !== 'string')
      errorMessage = 'An error has occurred'
  }

  return errorMessage
}

export default resolveErrorMessage
