import React, { Component } from 'react'
import { Text, Button } from '@/components/common'
import { report } from '@/utils'

interface Props {
  children: React.ReactElement
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    report.error(error)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <div className="flex h-full flex-1 flex-col items-center justify-center p-6 text-center">
          <Text as="h1" preset="heading.lg" className="mb-4 max-w-lg">
            Oh dear, something unexpected happened.
          </Text>

          <Text as="p" preset="body.lg" className="mb-6 max-w-lg">
            Please click below to reload the page.
          </Text>

          <Button onClick={() => window.location.reload()}>
            Reload This Page
          </Button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
