import React, { useCallback } from 'react'
import { NotifiableError } from '@bugsnag/js'
import { Campaign } from '@/services/api/campaign'
import { Investment } from '@/services/api/investment'
import { getTipaltiIframeUrl } from '@/services/api/tipalti'
import { report } from '@/utils'
import Button from '../Button/Button'
import Type from '../Type/Type'
import PayoutModal from './PayoutModal'

export const REGISTRATION_MESSAGE = {
  NOT_PAYABLE: `Angel Studios has partnered with Tipalti, a secure service that handles distribution of funds and will process your payout, should one occur. You can register or edit your payout information with them by clicking the "Update" button. Failing to register with Tipalti could delay the transfer of funds.`,
  PAYABLE: `You’re already registered with Angel Studios' secure partner, Tipalti. Should you need to edit your payout information, click the "Update" button.`,
}

interface Props {
  campaign: Campaign
  className?: string
  investment?: Investment
  setTipaltiOpened?: (field: string, value: boolean) => void
}

const TipaltiPayout: React.FC<Props> = ({
  campaign,
  className,
  investment,
  setTipaltiOpened,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [tipaltiIframeUrl, setTiplatiIframeUrl] = React.useState('')
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const message = investment?.isTipaltiPayable
    ? REGISTRATION_MESSAGE.PAYABLE
    : REGISTRATION_MESSAGE.NOT_PAYABLE

  const handlePayoutButton = useCallback(async () => {
    if (!investment?.id) return

    try {
      setError('')
      setIsLoading(true)
      const { iframeUrl } = await getTipaltiIframeUrl(investment.id)
      setTiplatiIframeUrl(iframeUrl)
      setIsModalOpen(true)
      setTipaltiOpened && setTipaltiOpened('payment.hasTipaltiInfo', true)
    } catch (e) {
      report.error(e as NotifiableError)
      setError(
        'There was an error loading the Tipalti registration form. Please try again later.'
      )
    } finally {
      setIsLoading(false)
    }
  }, [investment?.id, setTipaltiOpened])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  if (!campaign.supportTipaltiPayback || !investment) {
    return null
  }

  return (
    <>
      <div className={className}>
        <Type as="h2" variant="title-md" className="mb-3">
          Payout Method
        </Type>
        <Type as="p" variant="paragraph-sm" className="mb-3">
          {message}
        </Type>

        <Button
          variant="primary"
          color="black"
          size="sm"
          onClick={handlePayoutButton}
          disabled={isLoading}
          type="button"
        >
          Update
        </Button>
        {error && (
          <Type as="p" variant="caption-md" className="mt-3 text-red">
            {error}
          </Type>
        )}
      </div>
      <PayoutModal
        iframeUrl={tipaltiIframeUrl}
        isModalOpen={isModalOpen}
        setIsModalOpen={handleCloseModal}
      />
    </>
  )
}

export default TipaltiPayout
