import React from 'react'
import classNames from 'classnames'

const gradientMap = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
}

const Scrim = ({
  position = 'left',
  startColor = 'rgba(0,0,0,1)',
  startColorStop = '0%',
  endColor = 'rgba(0,0,0,0)',
  endColorStop = '100%',
  distance = '100%',
  className = '',
  style = {},
}) => {
  return (
    <div
      className={classNames(
        {
          'pointer-events-none absolute': true,
          'bottom-0 left-0 left-0 top-0': position === 'left',
          'bottom-0 right-0 top-0': position === 'right',
          'left-0 right-0 top-0': position === 'top',
          'bottom-0 left-0 right-0': position === 'bottom',
        },
        [className]
      )}
      style={{
        background: `linear-gradient(to ${gradientMap[position]}, ${startColor} ${startColorStop}, ${endColor} ${endColorStop})`,
        width: position === 'left' || position === 'right' ? distance : 'auto',
        height: position === 'top' || position === 'bottom' ? distance : 'auto',
        ...style,
      }}
    />
  )
}

export default Scrim
