import { Context, GrowthBook } from '@growthbook/growthbook-react'
import { TrackEvents } from '@/services/analytics/SegmentHandler/types'
import { AppFeatures } from './app-features'
import { TrackingData } from './types'

export const getGrowthbook = (context: Partial<Context> = {}) =>
  new GrowthBook<AppFeatures>({
    apiHost: 'https://funding.growthbook-proxy.angelstudios.com',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    subscribeToChanges: true,
    backgroundSync: true,
    enableDevMode: true,
    ...context,
  })

export const shouldStringifyValue = (value: unknown) => {
  return typeof value === 'object' || Array.isArray(value)
}

export const trackExperimentExposure = async (
  experiment: TrackingData['experiment'],
  result: TrackingData['result'],
  trackFn: (
    experimentType: keyof TrackEvents,
    {
      name,
      variation,
    }: { name: string; variation: string | boolean | Record<string, unknown> }
  ) => Promise<void>
) => {
  const resultValue = shouldStringifyValue(result.value)
    ? JSON.stringify(result.value)
    : result.value
  await trackFn('Experiment Impression', {
    name: experiment.key,
    variation: resultValue,
  })
}
