import { SWRConfiguration } from 'swr'
import { getAuthenticatedParty, Party } from '@/services/api/party'
import useApi from '../useApiV2'
import useAuth from '../useAuth'

const useParty = (config?: SWRConfiguration) => {
  const { user } = useAuth()

  const { data, ...rest } = useApi<Party>({
    key: user ? `parties` : false,
    request: getAuthenticatedParty,
    config,
  })

  return { party: data, ...rest }
}

export default useParty
