import React from 'react'

interface Props {
  className?: string
  disabled?: boolean
}

const ChatSubmitButton: React.FC<Props> = ({ className, disabled }) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" fill={disabled ? '#63696f' : '#39c4a6'} r="16" />
      <circle cx="16" cy="16" r="15.5" stroke="#000" strokeOpacity=".2" />
      <path
        clipRule="evenodd"
        d="M16.707 7.043a1 1 0 0 0-1.414 0l-6.75 6.75a1 1 0 1 0 1.414 1.414L15 10.164V24.25a1 1 0 1 0 2 0V10.164l5.043 5.043a1 1 0 0 0 1.414-1.414z"
        fill={disabled ? '#c1c6cb' : '#005e50'}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ChatSubmitButton
