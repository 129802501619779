import React from 'react'
import { Campaign } from '@/services/api/campaign'
import { formatMoney } from '@/utils'
import InfoPopover from '../Popover/InfoPopover'
import Type from '../Type'
import { MONEY_FORMAT } from './utils'

interface Props {
  campaign: Campaign
}

const PriorRaises: React.FC<Props> = ({ campaign }) => {
  if (campaign.priorRaises.length === 0) return null
  return (
    <Type
      as="div"
      variant="caption-md"
      className="flex gap-1 text-core-gray-600"
    >
      Previous Rounds
      <InfoPopover
        className="text-core-oxide"
        content={
          <div>
            <div className="mb-6 flex flex-row">
              {campaign.priorRaiseTotal?.backers && (
                <div className="mr-5">
                  <Type as="p" variant="title-md" className="font-semibold">
                    {campaign.priorRaiseTotal.backers.toLocaleString()}
                  </Type>
                  <Type as="p" variant="paragraph-sm">
                    Backers
                  </Type>
                </div>
              )}
              <div>
                <Type as="p" variant="title-md" className="font-semibold">
                  {formatMoney(campaign.priorRaiseTotal?.amount, MONEY_FORMAT)}
                </Type>
                <Type as="p" variant="paragraph-sm">
                  Raised
                </Type>
              </div>
            </div>

            <Type as="h5" variant="title-sm" className="mb-2 font-semibold">
              Previous Rounds
            </Type>
            <ul className="flex flex-col gap-2">
              {campaign.priorRaises.map((round) => (
                <li key={campaign.name + round.closeDate + round.amountRaised}>
                  <Type
                    as="p"
                    variant="paragraph-sm"
                    className="leading-normal"
                  >
                    <span className="mr-2">
                      {round.closeDate}
                      {round.roundType ? ` ${round.roundType}:` : ':'}
                    </span>
                    {round.numBackers &&
                      `${round.numBackers.toLocaleString()} Backers, `}
                    {formatMoney(round.amountRaised, MONEY_FORMAT) + ' Raised'}
                  </Type>
                </li>
              ))}
            </ul>
          </div>
        }
      />
    </Type>
  )
}

export default PriorRaises
