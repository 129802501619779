import React, {
  useState,
  useCallback,
  forwardRef,
  useEffect,
  useMemo,
} from 'react'
import classNames from 'classnames'
import Iframe from '@/components/common/Iframe'
import InView from '@/components/common/InView'
import { AngelFundingLogo } from '@/components/svg'
import useNonCallbackForwardedRef from '@/services/hooks/useNonCallbackForwardedRef'
import useVideoBackgroundImage from '@/services/hooks/useVideoBackgroundImage'
import VideoBoxPlayerWrap from './VideoBoxPlayerWrap'
import { VideoBoxProps, VideoPlayer } from './types'
import { getIframeSrc } from './utils'

const VideoBox = (
  {
    id,
    video,
    aspectRatio = 16 / 9,
    useThumbnailBackground = true,
    lazyload = true,
    params,
    allow,
    fadeInOnLoad,
    className,
    style = {},
    service = 'youtube',
    ...rest
  }: VideoBoxProps,
  forwardedRef: React.ForwardedRef<VideoPlayer | null> | undefined
) => {
  const playerRef = useNonCallbackForwardedRef(forwardedRef)
  const [inView, setInView] = useState(!lazyload)
  const [origin, setOrigin] = useState('')
  const { src: backgroundSrc } = useVideoBackgroundImage({
    video,
    disabled: !inView || !useThumbnailBackground,
    service,
  })

  const iframeSrc = useMemo(
    () => (origin ? getIframeSrc(video, { ...params, origin }, service) : null),
    [video, params, origin, service]
  )

  const onEnter = useCallback(() => {
    if (lazyload) {
      setInView(true)
    }
  }, [lazyload, setInView])

  const paddingBottom = Number.isNaN(aspectRatio)
    ? `${(9 / 16) * 100}%`
    : `${100 / aspectRatio}%`

  useEffect(() => setOrigin(window.location.origin), [])

  return (
    <InView
      onEnter={onEnter}
      unobserveAfterEntry
      className={classNames('relative', className)}
      style={{ paddingBottom, ...style }}
    >
      {backgroundSrc && (
        <div
          className="absolute inset-0 w-full"
          style={{
            backgroundImage: `url(${backgroundSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      )}

      <div
        className="absolute inset-0 flex items-center justify-center bg-gray-8 p-6"
        style={{
          pointerEvents: backgroundSrc ? 'none' : 'auto',
          opacity: backgroundSrc ? 0 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <AngelFundingLogo
          fill1="#B0B0B0"
          fill2="#B0B0B0"
          className="max-w-lg"
        />
      </div>

      {inView && iframeSrc && (
        <VideoBoxPlayerWrap
          playerRef={playerRef}
          id={id}
          video={video}
          service={service}
          {...rest}
        >
          <Iframe
            id={id}
            data-cy="video-player"
            src={iframeSrc}
            allow={allow}
            className="absolute inset-0"
            width="100%"
            height="100%"
            fadeInOnLoad={fadeInOnLoad}
          />
        </VideoBoxPlayerWrap>
      )}
    </InView>
  )
}

export default forwardRef(VideoBox)
