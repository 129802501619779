import IntersectionObserverPolyfill from 'intersection-observer'

const hasIntersectionObserver =
  typeof window !== 'undefined' && !!window.IntersectionObserver

const IntersectionObserver = hasIntersectionObserver
  ? window.IntersectionObserver
  : IntersectionObserverPolyfill

function createObserver(handler, el, observerOptions) {
  const observer = new IntersectionObserver(handler, observerOptions)
  observer.observe(el)
  return observer
}

export default createObserver
