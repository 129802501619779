import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import InterstitialLink from '@/components/InterstitialLink'
import { MarkdownModal, Text } from '@/components/common'
import Image from '@/components/contentful/Image'
import { AngelFundingLogo } from '@/components/svg'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { getNextRouterQueryParam } from '@/utils'
import MenuItem from './MenuItem'

const Footer: React.FC = () => {
  const router = useRouter()
  const [modalContent, setModalContent] = useState<string | undefined>()
  const [modalHeading, setModalHeading] = useState<string | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const siteAssets = useSiteAssets()

  const currentYear = new Date().getFullYear()

  const openModal = (content?: string, heading?: string) => {
    setModalContent(content)
    setModalHeading(heading)
    setIsModalOpen(true)
  }

  useEffect(() => {
    const value = getNextRouterQueryParam('education', router)
    if (value === 'true' && siteAssets.howCrowdfundingWorks) {
      openModal(siteAssets.howCrowdfundingWorks)
    }
  }, [router, siteAssets.howCrowdfundingWorks])

  return (
    <footer className="bg-black px-2 pb-4 pt-12 font-whitney text-xs text-white md:px-4 md:pb-12">
      <div className="flex flex-col items-center justify-center text-center md:flex-row">
        <div className="inline-flex flex-col items-center">
          {siteAssets.footerLogo && (
            <Link href="/">
              <Image image={siteAssets.footerLogo} height={40} />
            </Link>
          )}
          {!siteAssets.footerLogo && siteAssets.isWhiteLabel && (
            <InterstitialLink domain="invest.angel.com">
              <Text as="p" preset="body.xs" className="mb-2">
                Powered by
              </Text>
              <AngelFundingLogo
                className="h-[40px]"
                fill1="#fff"
                fill2="#fff"
              />
            </InterstitialLink>
          )}
          {!siteAssets.footerLogo && !siteAssets.isWhiteLabel && (
            <Link href="/">
              <AngelFundingLogo
                className="h-[40px]"
                fill1="#fff"
                fill2="#fff"
              />
            </Link>
          )}
          <Text
            as="p"
            preset="custom"
            className="mt-4 hidden uppercase md:block"
          >
            COPYRIGHT © {currentYear}, {siteAssets.copyrightName}.
          </Text>
          <Text as="p" preset="custom" className="hidden md:block">
            ALL RIGHTS RESERVED.
          </Text>
        </div>

        <menu className="mt-5 inline-flex flex-col flex-wrap justify-center text-center text-base md:ml-6 md:mt-0 md:flex-row">
          <MenuItem
            label="Privacy"
            showItem={!!siteAssets?.privacy}
            onClick={() => openModal(siteAssets?.privacy, 'Privacy')}
          />
          <MenuItem
            label="Terms of Service"
            showItem={
              !!(siteAssets?.termsOfServiceIntro || siteAssets?.termsOfService)
            }
            onClick={() =>
              openModal(
                [
                  siteAssets?.termsOfServiceIntro,
                  siteAssets?.termsOfService,
                ].join('\n'),
                'Terms of Service'
              )
            }
          />
          <MenuItem
            label="How Crowdfunding Works"
            showItem={!!siteAssets?.howCrowdfundingWorks}
            onClick={() => openModal(siteAssets?.howCrowdfundingWorks)}
          />
          <MenuItem
            label="Electronic Consent"
            showItem={!!siteAssets?.electronicConsent}
            onClick={() => openModal(siteAssets?.electronicConsent)}
          />
          <MenuItem
            label="Portal Disclosures"
            showItem={!!siteAssets?.portalDisclosures}
            onClick={() => openModal(siteAssets?.portalDisclosures)}
          />
        </menu>

        <Text as="p" preset="custom" className="mt-4 uppercase md:hidden">
          COPYRIGHT © {currentYear}, {siteAssets.copyrightName}.
        </Text>
        <Text as="p" preset="custom" className="md:hidden">
          ALL RIGHTS RESERVED.
        </Text>
      </div>

      <Text
        as="p"
        preset="body.sm"
        className="mx-auto mt-10 max-w-4xl whitespace-pre-wrap break-words px-4 text-center"
      >
        {siteAssets?.footerDisclaimer}
      </Text>

      {modalContent && (
        <MarkdownModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          info={modalContent}
          heading={modalHeading}
        />
      )}
    </footer>
  )
}

export default Footer
