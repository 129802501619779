import authClient from '../authClient'
import thinMintClient from '../thinMintClient'
import { resolveAuthDown } from './resolver'
import { ServerAuth } from './types'

const getStatsId = () => {
  const array = new Uint32Array(10)
  crypto.getRandomValues(array)
}

export const emailLogin = (password: string, username: string) =>
  authClient
    .post<ServerAuth>('/auth/login', {
      password,
      username,
      source: 'invest',
    })
    .then((res) => resolveAuthDown(res.data))

export const facebookLogin = (token: string) =>
  authClient
    .get<ServerAuth>('/auth/facebook', {
      params: {
        token,
        source: 'invest',
        anon_stats_id: getStatsId(),
      },
    })
    .then((res) => resolveAuthDown(res.data))

export const googleLogin = (state: string, code: string) =>
  authClient
    .get<ServerAuth>('/auth/google', {
      params: {
        state,
        code,
        source: 'invest',
        anon_stats_id: getStatsId(),
        redirect_uri: `${location.protocol}//${location.host}`,
      },
    })
    .then((res) => resolveAuthDown(res.data))

export const sendEmailVerification = (email: string) =>
  thinMintClient.post('/users/email-verification/', { email })

export const register = (
  email: string,
  password: string,
  verification_code: string
) =>
  thinMintClient.post('/users/', {
    agreed_to_investor_terms: true,
    auto_generated: false,
    email,
    password,
    verification_code,
    welcome_email: 'portal',
    signup_location: 'studios',
  })

export const resetPasswordEmail = (email: string) =>
  thinMintClient.post('/password/request/', { email })

export const resetPassword = (
  password: string,
  password_confirm: string,
  validation_key: string
) =>
  thinMintClient.post('/password/reset/', {
    password,
    password_confirm,
    validation_key,
  })
