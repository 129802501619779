import React from 'react'
import classnames from 'classnames'
import Link from 'next/link'
import { FormatMoneyOptions } from 'src/utils/formatMoney'
import SellingFast from '@/components/common/CampaignCard/SellingFast'
import Image from '@/components/contentful/Image'
import { Campaign as ICampaign } from '@/services/api/campaign'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import { formatMoney, progressUtil } from '@/utils'
import Button from '../Button'
import ImageLoader from '../ImageLoader'
import Text from '../Text/Text'
import Callout from './Callout'

interface Props {
  campaign: ICampaign
  className?: string
  noDescription?: boolean
}

const MONEY_FORMAT: FormatMoneyOptions = {
  zeroFractionDigits: true,
}

const VerticalCampaignCard: React.FC<Props> = ({
  campaign,
  className,
  noDescription,
}) => {
  const isTestingTheWaters = campaign.status === 'testing_the_waters'
  const { campaignAssets, isLoading } = useCampaignAssets(campaign.slug)

  const clarificationSymbol = campaign.isSellingFast ? '†' : ''

  return (
    <div
      className={classnames(
        'flex w-full flex-col overflow-hidden rounded-lg shadow shadow-gray-8',
        className
      )}
    >
      {isLoading && <ImageLoader uniqueKey={campaign.slug} />}
      {!isLoading && campaignAssets?.image && (
        // div is necessary to maintain image aspect-ratio because of flex-col on parent
        <div>
          <Image
            className="h-full w-auto max-w-none"
            containerClassName="aspect-video"
            image={campaignAssets.image}
            width={327}
            height={184}
            fit="fill"
            lazy
          />
        </div>
      )}
      <div className="bg-white px-6 pb-5 pt-4 text-left md:px-4 md:pb-4 md:pt-2">
        <Text
          as="h4"
          preset="custom"
          className="text-xl leading-normal tracking-tighter md:text-lg"
        >
          {campaign.name}
        </Text>
        {campaignAssets?.subtitle && (
          <Text
            as="p"
            preset="custom"
            className="mb-1 text-base leading-tight tracking-none md:text-xs md:leading-tight md:tracking-none"
          >
            {campaignAssets.subtitle}
          </Text>
        )}
        <Text
          as="p"
          preset="custom"
          className="mb-4 text-sm capitalize leading-normal tracking-tighter md:text-2xs"
        >
          {`${campaign.type} | Offering: ${campaign.offeringModel}`}
        </Text>
        {campaign.shortAbout && !noDescription && (
          <Text as="p" preset="body.md">
            {campaign.shortAbout}
          </Text>
        )}
        {!isTestingTheWaters && (
          <div className="mb-4">
            {campaign.capitalRaised >= campaign.minimumCapitalGoal &&
              campaign.stretchGoal && (
                <Text as="p" preset="body.xs" className="mb-2 font-semibold">
                  {campaign.stretchGoal.title}
                </Text>
              )}
            <div className="mb-1 h-3 w-full rounded-lg bg-gray-8">
              <div
                className="h-full rounded-lg bg-oxide-800"
                style={{
                  // needs min, or it looks bad with rounded corners
                  minWidth: 8,
                  width: progressUtil.getProgressWidth(campaign.progress),
                }}
              />
            </div>
            <Text as="p" preset="body.sm">
              <Text
                as="span"
                preset="custom"
                className="text-lg leading-normal tracking-none md:text-base"
              >
                {formatMoney(campaign.amountRaised, MONEY_FORMAT)}
              </Text>{' '}
              {campaign.progressWithGoalString}
            </Text>
          </div>
        )}
        {/* Pledged and Backers row */}
        {isTestingTheWaters ? (
          <div className="flex flex-row flex-wrap">
            <Callout
              className="md:mr-2"
              stat={formatMoney(campaign.amountRaised, MONEY_FORMAT)}
              label="Interest Expressed"
            />
            <Callout
              stat={Number(campaign.backers).toLocaleString()}
              label="Backers"
            />
          </div>
        ) : (
          <div className="flex flex-wrap">
            <Callout
              className="md:mr-2"
              stat={Number(campaign.backers).toLocaleString()}
              label="Backers"
            />
            <Callout
              stat={Number(
                campaign.timeRemaining.timeRemainingValue
              ).toLocaleString()}
              label={`${campaign.timeRemaining.timeRemainingDimension} left ${clarificationSymbol}`}
            />
            <SellingFast
              campaign={campaign}
              className="my-2 text-black"
              showHeading={false}
            />
          </div>
        )}
      </div>

      <Link href={`/${campaign.slug}`} className="mt-auto">
        <Button type="button" tabIndex={-1} className="w-full" rounded={false}>
          {isTestingTheWaters ? 'Express Interest' : 'Back this Project'}
        </Button>
      </Link>
    </div>
  )
}

export default VerticalCampaignCard
