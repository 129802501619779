import axios from 'axios'
import { ServerGuildMembershipCount } from './types'

export const baseEndpoint = String(process.env.NEXT_PUBLIC_HYDRA_REST_API_URL)

export const getGuildMembershipCount = async () => {
  return axios
    .post<ServerGuildMembershipCount>(
      baseEndpoint,
      {
        query: `query getGuildMembershipCount {
        guildMembershipCount
      }`,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(({ data }) => data.data)
}

export const keyGen = {
  getGuildMembershipCount: () => 'getGuildMembershipCount',
}
