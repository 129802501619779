import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import ButtonBase from '@/components/common/ButtonBase'
import { ChevronRight } from '@/components/svg'

export interface Props {
  active: boolean
  orientation: 'horizontal' | 'vertical'
  direction: 'back' | 'forward'
  onClick: (options?: ScrollOptions) => void
  className?: string
  style?: CSSProperties
}

const CarouselNav: React.FC<Props> = ({
  active,
  orientation,
  direction,
  onClick,
  className,
  style,
}) => {
  return (
    <div
      className={classNames(
        'pointer-events-none absolute flex items-center justify-center',
        'transition-opacity duration-300',
        {
          'bottom-0 top-0': orientation === 'horizontal',
          'left-0 right-0': orientation === 'vertical',
          'left-0': orientation === 'horizontal' && direction === 'back',
          'right-0': orientation === 'horizontal' && direction === 'forward',
          'top-0': orientation === 'vertical' && direction === 'back',
          'bottom-0': orientation === 'vertical' && direction === 'forward',
          'opacity-0': !active,
        },
        className
      )}
      style={style}
    >
      <ButtonBase
        onClick={() => onClick()}
        className={classNames(
          'pointer-events-auto rounded-full border-2 border-white bg-core-gray-950 p-1',
          { 'pointer-events-none': !active }
        )}
        aria-label={`Scroll ${direction}`}
        disabled={!active}
      >
        <ChevronRight
          className={classNames('h-5 w-5 text-white', {
            'rotate-180': orientation === 'horizontal' && direction === 'back',
            'rotate-[270deg]':
              orientation === 'vertical' && direction === 'back',
            'rotate-90': orientation === 'vertical' && direction === 'forward',
          })}
        />
      </ButtonBase>
    </div>
  )
}

export default CarouselNav
