import React from 'react'
import classNames from 'classnames'

export default function LoginForm({
  className = '',
  textSizeClassName = 'text-base',
  ...rest
}) {
  return (
    <input
      className={classNames(
        {
          'appearance-none focus:outline-none': true,
          'block h-12 w-full px-4': true,
          'rounded-full border border-gray-5 bg-white': true,
          [`${textSizeClassName} leading-normal text-black`]: true,
        },
        className
      )}
      {...rest}
    />
  )
}
