import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Text, ButtonBase } from '@/components/common'
import { ToastNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import { Exclamation, Close } from '@/components/svg'

export interface Props extends Omit<Notification, 'type' | 'id'> {
  handleRemove: () => void
}

export const ToastNotification: React.FC<Props> = ({
  variant,
  text,
  actions = [],
  dismissable = true,
  timeout,
  handleRemove,
}) => {
  useEffect(() => {
    let timeoutInstance: ReturnType<typeof setTimeout>
    if (timeout) {
      timeoutInstance = setTimeout(() => {
        handleRemove()
      }, timeout)
    }

    return () => {
      if (timeoutInstance) {
        clearTimeout(timeoutInstance)
      }
    }
  }, [timeout, handleRemove])

  return (
    <div className="mb-4 flex max-w-2xl items-center rounded-sm bg-gray-1 px-2 py-3">
      <Exclamation
        className={classNames({
          'mr-2 h-6 w-6 flex-shrink-0': true,
          'text-oxide-3': variant === 'warning',
          'text-red': variant === 'error',
        })}
      />
      <Text as="p" preset="body.md" className="font-light text-white">
        {text}
      </Text>

      <div className="ml-auto inline-flex items-center pl-2">
        {actions.map(
          ({ label, onClick, variant: actionVariant = 'primary' }, idx) => (
            <ButtonBase
              key={idx}
              onClick={() => {
                onClick()
                handleRemove()
              }}
              className={classNames({
                'my-1 whitespace-nowrap border-gray-5 pl-2 leading-none first:border-l-0 sm:my-0 sm:ml-2 sm:border-l':
                  true,
                'font-semibold text-oxide-3':
                  actionVariant === 'primary' && variant === 'warning',
                'font-semibold text-red':
                  actionVariant === 'primary' && variant === 'error',
                'font-light text-gray-8': actionVariant === 'secondary',
              })}
            >
              <Text as="span" preset="body.md">
                {label}
              </Text>
            </ButtonBase>
          )
        )}

        {dismissable && (
          <ButtonBase
            onClick={handleRemove}
            className="my-1 border-gray-5 pl-2 first:border-l-0 sm:my-0 sm:ml-2 sm:border-l"
            aria-label="Close notification"
          >
            <Close className="h-5 w-5 text-white" />
          </ButtonBase>
        )}
      </div>
    </div>
  )
}

export default ToastNotification
