const APP_ID = '259105908812835'

export const loadFacebookScript = () => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v10.0',
    })
  }

  return new Promise((resolve, reject) => {
    if (window.FB) return resolve(true)
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.id = 'facebook-jssdk'
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  })
}

// This is currently not used as it changes the behavior of the oauth flow
// when users re-authenticate with FB. We can revisit this after AB testing
// into the new FB Auth Button
export const checkFacebookAuthStatus = () =>
  new Promise((resolve) => {
    window.FB.getLoginStatus((response) => {
      if (response.authResponse?.accessToken) {
        resolve(response.authResponse.accessToken)
      } else {
        resolve(null)
      }
    }, true) // Need this bool arg so FB will re-validate user's auth status during login
  })

export const loginWithFacebook = () =>
  new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        if (response.authResponse?.accessToken) {
          resolve(response.authResponse.accessToken)
        } else {
          reject(new Error('Unable to authenticate with Facebook'))
        }
      },
      { scope: 'public_profile,email' }
    )
  })
