import React from 'react'
import classnames from 'classnames'
import Link from 'next/link'
import ContentImage from '@/components/contentful/Image'
import { Campaign as ICampaign } from '@/services/api/campaign'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import ImageLoader from '../ImageLoader'
import Type from '../Type'
import CurrentlyFundingSection from './CurrentlyFundingSection'
import PreviouslyFundedSection from './PreviouslyFundedSection'
import TestingTheWatersSection from './TestingTheWatersSection'

interface Props {
  campaign: ICampaign
  className?: string
  layout?: 'vertical' | 'horizontal'
}

const CampaignCard: React.FC<Props> = ({
  campaign,
  className,
  layout = 'vertical',
}) => {
  const url = `/${campaign.slug}`
  // 16:9 aspect ratio
  const imgHeight = 341
  const imgWidth = 607 // max width it will need

  const { campaignAssets, isLoading } = useCampaignAssets(campaign.slug)

  return (
    <div
      className={classnames(
        'flex h-full w-full flex-col overflow-hidden rounded-2xl border border-core-gray-300 bg-white',
        {
          'sm:flex-row': layout === 'horizontal',
        },
        className
      )}
    >
      <div
        className={classnames({
          'bg-core-gray-950 sm:flex-[1_0_50%]': layout === 'horizontal',
        })}
      >
        {isLoading && <ImageLoader uniqueKey={campaign.slug} />}
        {!isLoading && campaignAssets?.image && (
          <Link href={url}>
            <ContentImage
              className={classnames({
                'h-full w-auto max-w-none': layout === 'vertical',
                'w-full ': layout === 'horizontal',
              })}
              containerClassName={classnames('aspect-video', {
                'flex items-center w-full sm:hidden md:flex':
                  layout === 'horizontal',
              })}
              image={campaignAssets.image}
              fit="fill"
              focus="face"
              lazy
              height={imgHeight}
              width={imgWidth}
            />
            {layout === 'horizontal' && (
              <ContentImage
                className="w-full"
                containerClassName={classnames('aspect-square', {
                  'hidden sm:block md:hidden': layout === 'horizontal',
                })}
                image={campaignAssets.squareImage}
                fit="fill"
                focus="face"
                lazy
                height={367}
                width={367}
              />
            )}
          </Link>
        )}
      </div>

      <div
        className={classnames(
          'flex flex-1 flex-col gap-6 p-6 text-left text-core-gray-900',
          {
            'sm:-order-1 sm:max-w-[428px] sm:flex-[0_0_50%]':
              layout === 'horizontal',
          }
        )}
      >
        <div>
          <Link href={url} className="mb-2 inline-block self-start">
            <Type as="h4" variant="title-md" className="font-bold">
              {campaign.name}
            </Type>
            {campaignAssets?.subtitle && (
              <Type as="p" variant="paragraph-sm">
                {campaignAssets.subtitle}
              </Type>
            )}
          </Link>
          <ul className="flex items-start">
            {campaign.type && (
              <li className="border-r px-2 leading-[0] first:pl-0 last:border-none last:pr-0">
                <Type
                  as="span"
                  variant="eyebrow-sm"
                  className="font-semibold uppercase text-core-gray-600"
                >
                  {campaign.type}
                </Type>
              </li>
            )}
            <li className="border-r px-2 leading-[0] first:pl-0 last:border-none last:pr-0">
              <Type
                as="span"
                variant="eyebrow-sm"
                className="font-semibold uppercase text-core-gray-600"
              >
                {campaign.offeringModel}
              </Type>
            </li>
          </ul>
        </div>

        {campaign.shortAbout && (
          <Type
            as="p"
            variant="paragraph-sm"
            className="line-clamp-2 leading-loose text-core-gray-700"
          >
            {campaign.shortAbout}
          </Type>
        )}

        {campaign.status === 'currently_funding' && (
          <CurrentlyFundingSection campaign={campaign} url={url} />
        )}
        {campaign.status === 'testing_the_waters' && (
          <TestingTheWatersSection campaign={campaign} url={url} />
        )}
        {(campaign.status === 'closed' || campaign.status === 'archived') && (
          <PreviouslyFundedSection campaign={campaign} url={url} />
        )}
      </div>
    </div>
  )
}

export default CampaignCard
