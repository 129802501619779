import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Asset } from 'contentful'
import Image from '@/components/contentful/Image'
import { FacebookCircle, TwitterCircle, EmailCircle } from '@/components/svg'

interface Props {
  className?: string
  emailSubject?: string
  icon?: Asset
  name?: string
  preconfigured: 'Custom' | 'Facebook' | 'Twitter' | 'Email'
  url?: string
  path: string
}

const SocialLink: React.FC<Props> = ({
  className,
  emailSubject = '',
  icon,
  name,
  preconfigured,
  url,
  path,
}) => {
  const [shareUrl, setShareUrl] = useState('')

  const body = encodeURIComponent(shareUrl)

  const subject = encodeURIComponent(emailSubject)

  useEffect(() => {
    if (!path) return
    setShareUrl(`${window.location.origin}/${path}?utm_medium=share_button`)
  }, [path])

  const items = useMemo(
    () => ({
      Custom: {
        Icon: null,
        href: url,
      },
      Email: {
        Icon: EmailCircle,
        href: `mailto:?subject=${subject}&body=${body}`,
      },
      Facebook: {
        Icon: FacebookCircle,
        href: `https://www.facebook.com/sharer/sharer.php?u=${body}`,
      },
      Twitter: {
        Icon: TwitterCircle,
        href: `https://twitter.com/intent/tweet?url=${body}`,
      },
    }),
    [body, subject, url]
  )
  const { href, Icon } = items[preconfigured]

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classnames(
        'flex cursor-pointer items-center rounded border border-gray-7 p-2 text-sm hover:border-gray-5',
        className
      )}
    >
      <span className="inline-block w-6 flex-shrink-0">
        {icon && <Image image={icon} width={24} />}
        {!icon && typeof Icon === 'function' && <Icon />}
      </span>
      {name && <span className="ml-2">{name}</span>}
    </a>
  )
}

export default SocialLink
