import { casingUtil } from '@/utils'
import { ServerUser } from './types'

export const resolveUserDown = ({
  metabase_dashboards,
  ...rest
}: ServerUser) => ({
  ...casingUtil.snakeToCamel(rest),
  metabaseDashboards: metabase_dashboards.map((item) => {
    const [id, name] = Object.entries(item)[0]
    return { id, name }
  }),
})
