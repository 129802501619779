import { Chat, getChat } from '@/services/api/chatbot'
import useApi from '@/services/hooks/useApiV2'

const useChat = (slug: string) => {
  const { data, isLoading, isError } = useApi<Chat[]>({
    key: `chatbot/${slug}`,
    request: () => getChat(),
  })

  return {
    data,
    isLoading,
    isError,
  }
}

export default useChat
