import { createContext } from 'react'
import { SiteAssets, resolveSiteAssets } from '@/services/api/siteAssets'

const SiteAssetsContext = createContext<SiteAssets>(
  resolveSiteAssets(
    {
      contentfulName: 'invest.angel.com',
      electronicConsent: '',
      howCrowdfundingWorks: '',
      portalDisclosures: '',
      privacy: '',
      termsOfService: '',
      termsOfServiceIntro: '',
    },
    'invest.angel.com'
  )
)

export default SiteAssetsContext
