import React, { useCallback, useMemo, useState } from 'react'
import 'react-phone-number-input/style.css'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { CountryCode } from 'libphonenumber-js/core'
import PhoneInput from 'react-phone-number-input'
import { FormInput } from '../types'

export interface Props extends FormInput {
  onBlur?: (e: React.ChangeEvent) => void
  onFocus?: (e: React.ChangeEvent) => void
  countries?: CountryCode[]
}

// This func will return tailwind focus: classNames
// without the focus: prefix. Need this to apply focused classes
// on the phone input div when it's child input is focused
export const getFocusedClassNames = (className = '') => {
  return className
    .split(' ')
    .filter((c) => c.includes('focus:'))
    .map((c) => c.replace('focus:', '!'))
    .join(' ')
}

const FormPhoneInput: React.FC<Props> = ({
  id,
  name,
  value,
  onBlur,
  onFocus,
  countries,
  className,
}) => {
  const [focused, setFocused] = useState(false)
  const { setFieldValue } = useFormikContext()

  const handleValueChange = useCallback(
    (val = '') => setFieldValue(name, val),
    [name, setFieldValue]
  )

  // Don't show country selector if we're forcing one country
  const hideCountrySelector = useMemo(
    () => countries?.length === 1,
    [countries]
  )

  const inputComponent = useMemo(
    () =>
      React.forwardRef<HTMLInputElement>(function InputComponent(props, ref) {
        return (
          <input
            {...props}
            className="h-full w-full border-0 outline-none focus:border-0"
            ref={ref}
          />
        )
      }),
    []
  )

  // To hide the country selector, we create a custom countrySelectComponent
  // that returns `null`. The docs say we can import a different PhoneInput
  // component without the country selector, but this created other sytling issues
  const nullCountrySelectComponent = useMemo(() => {
    if (hideCountrySelector) {
      return React.forwardRef<HTMLSelectElement>(
        function CountrySelectorComponent() {
          return null
        }
      )
    }
  }, [hideCountrySelector])

  const resolvedOnFocus = useCallback(
    (e: React.ChangeEvent) => {
      onFocus?.(e)
      setFocused(true)
    },
    [onFocus, setFocused]
  )

  const resolvedOnBlur = useCallback(
    (e: React.ChangeEvent) => {
      onBlur?.(e)
      setFocused(false)
    },
    [onBlur, setFocused]
  )

  return (
    <PhoneInput
      id={id}
      name={name}
      defaultCountry="US"
      countries={countries}
      international={false}
      withCountryCallingCode={false}
      value={value}
      onChange={handleValueChange}
      onFocus={resolvedOnFocus}
      onBlur={resolvedOnBlur}
      className={classNames(
        'relative bg-core-white',
        {
          'pl-[55px]': !hideCountrySelector,
          [getFocusedClassNames(className)]: focused,
        },
        className
      )}
      inputComponent={inputComponent}
      countrySelectComponent={nullCountrySelectComponent}
    />
  )
}

export default FormPhoneInput
