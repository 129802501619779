import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

function richText(document: Document, options?: Options) {
  return documentToReactComponents(document, options)
}

export default richText
