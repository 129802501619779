import React from 'react'
import { Transition } from '@headlessui/react'
import { Chat } from '@/services/api/chatbot'
import Text from '../Text/Text'
import UserAvatar from './UserAvatar'

interface Props {
  message: Chat
}

const Message: React.FC<Props> = ({ message }) => {
  return (
    <Transition
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      appear
      show
    >
      <div className="xtransition-opacity mb-4 flex text-white duration-500">
        <UserAvatar
          avatarUrl={message.avatarUrl}
          name={message.name}
          className="mr-2 flex-shrink-0"
        />
        <div className="mt-1">
          <Text as="span" preset="body.md" className="mr-2 font-semibold">
            {message.name}
          </Text>
          <Text
            as="span"
            preset="custom"
            className="text-sm font-light tracking-tight text-gray-7"
          >
            {message.message}
          </Text>
        </div>
      </div>
    </Transition>
  )
}

export default Message
