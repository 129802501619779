import axios from 'axios'
import { getPersistedAuth } from '@/components/context/AuthContext/utils'

export const baseURL =
  process.env.NEXT_PUBLIC_THIN_MINT_API_URL ||
  'https://thin-mint.angelstudios.com/api'

const apiClient = axios.create({ baseURL })

// Add auth header to requests if token exists
apiClient.interceptors.request.use(async (config) => {
  const resolvedConfig = { ...config }

  const { token } = getPersistedAuth()

  if (token) {
    resolvedConfig.headers.Authorization = token
  }

  return resolvedConfig
})

// This can be helpful when debugging failing network requests. Uncomment to use.
// E.g., if trying to track down missing network request mocks while running tests with internet disabled
// this will log more details about the offending requests so you can see what needs to be mocked.
// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log('network error at:', (error as AxiosError).config?.url)
//     return error
//   }
// )

export default apiClient
