import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import Loader from 'react-loader-spinner'
import { ErrorBox, Text, Button, ButtonBase } from '@/components/common'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { sendEmailVerification } from '@/services/api/auth'
import { resolveErrorMessage, formUtil } from '@/utils'
import tailwind from '../../../tailwind.config'
import AuthInput from './AuthInput'
import VerifyCodeForm from './VerifyCodeForm'

export const Signup = ({ email, setEmail, view, setView }) => {
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const router = useRouter()

  const signUp = useCallback(
    async (e) => {
      e.preventDefault()

      if (!formUtil.validateEmail(email)) {
        setError('Please enter a valid email address')
        return
      }
      if (password.length < 8) {
        setError('Password must be at least 8 characters long')
        return
      }
      if (password !== passwordTwo) {
        setError('Passwords do not match')
        return
      }
      if (!agreeToTerms) {
        setError('Please agree to the terms and conditions.')
        return
      }

      setError('')
      setLoading(true)

      try {
        await sendEmailVerification(email)
        setLoading(false)
        setView('verifyEmail')
        SegmentHandler.track('Auth Completed', {
          location: router.asPath,
          value: 'register',
        })
      } catch (err) {
        setError(resolveErrorMessage(err))
      } finally {
        setLoading(false)
      }
    },
    [email, password, passwordTwo, agreeToTerms, router.asPath, setView]
  )

  return (
    <div className="flex h-full w-full flex-col justify-between">
      {view === 'register' ? (
        <form className="flex flex-col items-center" onSubmit={signUp}>
          <ErrorBox errorText={error} className="mb-4" />
          <div className="flex flex-col items-center">
            <div className={'flex flex-col items-center'}>
              <AuthInput
                name="email"
                type="email"
                placeholder="EMAIL"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <AuthInput
                name="password"
                type="password"
                placeholder="PASSWORD"
                className="mt-4"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <AuthInput
                name="secondPassword"
                type="password"
                placeholder="CONFIRM PASSWORD"
                className="mt-4"
                onChange={(e) => setPasswordTwo(e.target.value)}
                value={passwordTwo}
              />

              <div className="mt-4 flex flex-row items-start text-2xs">
                <input
                  id="terms-checkbox"
                  name="terms"
                  className="ml-2 mr-2 mt-[2px] text-primary"
                  type="checkbox"
                  onChange={(e) => {
                    setAgreeToTerms(e.target.checked)
                  }}
                  checked={agreeToTerms}
                />
                <Text
                  as="label"
                  preset="body.xs"
                  htmlFor="terms-checkbox"
                  className="uppercase"
                >
                  I agree to the{' '}
                  <a
                    className="text-secondary"
                    href="/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="/privacy"
                    className="text-secondary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy and Electronic Consent
                  </a>{' '}
                </Text>
              </div>

              <Button
                type="submit"
                disabled={loading}
                className="mt-4 flex w-full items-center justify-center rounded-full"
              >
                {loading && (
                  <Loader
                    type="TailSpin"
                    color={tailwind.theme.colors.lightGray}
                    className="mr-2"
                    height={25}
                    width={25}
                  />
                )}
                <span>Sign Up</span>
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <VerifyCodeForm email={email} password={password} />
      )}

      <div className="mt-6 flex w-full flex-col items-center">
        <Text as="h4" preset="heading.sm">
          Already a member?
        </Text>
        <ButtonBase
          className="text-sm text-core-oxide"
          onClick={() => setView('login')}
          disabled={loading}
        >
          Login
        </ButtonBase>
      </div>
    </div>
  )
}

export default Signup
