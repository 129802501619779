// abbreviate number to thousands and millions
export const abbreviateNumber = (num: number, decimalPlaces = 1) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(decimalPlaces) + 'M'
  } else if (num >= 1000) {
    return (num / 1000).toFixed(decimalPlaces) + 'K'
  } else {
    return num
  }
}
