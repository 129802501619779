import React, { useRef, useMemo } from 'react'

function useNonCallbackForwardedRef<T>(forwardedRef?: React.ForwardedRef<T>) {
  const defaultRef = useRef(null)
  const ref: React.MutableRefObject<T | null> = useMemo(() => {
    const isCallbackRef = typeof forwardedRef === 'function'

    if (isCallbackRef) {
      // eslint-disable-next-line no-console
      console.warn('Ref instance cannot be a callback ref')
    }

    return forwardedRef && !isCallbackRef ? forwardedRef : defaultRef
  }, [forwardedRef])

  return ref
}

export default useNonCallbackForwardedRef
