import { useState, useRef, useEffect, RefObject } from 'react'
import RO from 'resize-observer-polyfill'

const initialMeasure = {
  width: undefined,
  height: undefined,
  top: undefined,
  bottom: undefined,
  left: undefined,
  right: undefined,
  x: undefined,
  y: undefined,
  toJSON: undefined,
  inlineSize: undefined,
  blockSize: undefined,
}

type BorderBoxSize = { inlineSize: number; blockSize: number }
type Measure = Partial<DOMRectReadOnly & BorderBoxSize>

// Need to manually merge these as spread/assign doesn't always work on read-only objects
const mergeMeasure = (rect: DOMRectReadOnly, box?: BorderBoxSize) => {
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    bottom: rect.bottom,
    left: rect.left,
    right: rect.right,
    x: rect.x,
    y: rect.y,
    toJSON: rect.toJSON,
    inlineSize: box?.inlineSize || rect.width,
    blockSize: box?.blockSize || rect.height,
  }
}

function useMeasure<T extends HTMLElement>(): [RefObject<T>, Measure] {
  const observer = useRef<ResizeObserver>()
  const el = useRef<T>(null)
  const [measure, setMeasure] = useState<Measure>(initialMeasure)

  useEffect(() => {
    const handler = (entries: ResizeObserverEntry[]) => {
      setMeasure(
        mergeMeasure(entries[0].contentRect, entries[0].borderBoxSize?.[0])
      )
    }

    if (el.current && !observer.current) {
      observer.current = window.ResizeObserver
        ? new window.ResizeObserver(handler)
        : new RO(handler)
      observer.current.observe(el.current)
    }

    return () => {
      observer.current?.disconnect?.()
    }
  }, [])

  return [el, measure]
}

export default useMeasure
