import client from '../thinMintClient'
import { resolveInvestmentChatDown } from './resolver'
import { ServerInvestmentChat, Chat } from './types'

export const getInvestmentChat = (slug: string) =>
  client
    .get(`investment-chatbot?campaign=${slug}`)
    .then((res: { data: ServerInvestmentChat[] }) => {
      return resolveInvestmentChatDown(res.data)
    })

// TODO replace with client
export const getChat = () =>
  Promise.resolve<{ data: Chat[] }>({
    data: [],
  }).then((res: { data: Chat[] }) => res.data)
