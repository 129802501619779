import React from 'react'
import Loader from 'react-loader-spinner'
import { Text } from '@/components/common'
import { BusyNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import tailwind from '../../../../tailwind.config'

export type Props = Omit<Notification, 'type' | 'id'>

export const BusyNotification: React.FC<Props> = ({ heading, text }) => {
  return (
    <div className="flex max-w-xs flex-col items-center rounded-lg bg-white p-6">
      <Loader
        type="TailSpin"
        color={tailwind.theme.colors.oxide[2]}
        height={40}
        width={40}
      />

      <div className="mt-3 text-center">
        {heading && (
          <Text as="h4" preset="heading.sm" className="mb-2">
            {heading}
          </Text>
        )}

        <Text as="div" preset="body.sm">
          {text}
        </Text>
      </div>
    </div>
  )
}

export default BusyNotification
