import React from 'react'
import classnames from 'classnames'
import Text from '@/components/common/Text/Text'

interface Props {
  className?: string
  label: string
  stat: string
}

const Callout: React.FC<Props> = ({ className, label, stat }) => {
  return (
    <div
      className={classnames(
        'mb-2 flex-[0_0_100%] rounded-lg bg-core-gray-100 p-1.5 text-center last:mb-0 md:mb-0 md:flex-auto',
        className
      )}
    >
      <Text
        as="p"
        preset="custom"
        className="text-lg leading-normal tracking-none md:text-xs"
      >
        {stat}
      </Text>
      <Text
        as="p"
        preset="custom"
        className="text-xs leading-normal tracking-none md:text-2xs"
      >
        {label}
      </Text>
    </div>
  )
}

export default Callout
