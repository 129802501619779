import React, { useState, useCallback, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import qs from 'qs'
import { useSWRConfig } from 'swr'
import { Button, Type } from '@/components/common'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import useUser from '@/services/hooks/useUser'
import { buildShortbreadUrl } from '@/utils'
import AuthContext, { AuthModalView } from './AuthContext'
import useAuthTokenRefresher from './useAuthTokenRefresher'
import { getPersistedAuth, setPersistedAuth, clearPersistedAuth } from './utils'

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter()
  const { mutate } = useSWRConfig()
  const [userId, setUserId] = useState<string | null | undefined>(undefined)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalView, setModalView] = useState<AuthModalView>('verifyAccount')
  const [redirect, setRedirect] = useState<string | null>(null)
  const [dismissable, setDismissable] = useState(true)
  const {
    user,
    isError: userError,
    retryCount: userRetryCount,
  } = useUser(userId)
  const { ellisIslandClientId } = useSiteAssets()

  const startAuth = useCallback(
    (statePath = null) => {
      const query = qs.stringify({
        client_id: ellisIslandClientId,
        audience: process.env.NEXT_PUBLIC_ELLIS_ISLAND_AUDIENCE,
        scopes: 'openid email profile offline_access',
        redirect_uri: process.env.NEXT_PUBLIC_ELLIS_ISLAND_REDIRECT_URI,
        response_type: 'code',
        state: statePath
          ? buildShortbreadUrl(statePath)
          : buildShortbreadUrl(router.asPath),
      })

      const url = `${process.env.NEXT_PUBLIC_ELLIS_ISLAND_URL}/authorize?${query}`

      router.push(url)
    },
    [ellisIslandClientId, router]
  )

  const openModal = useCallback(
    (view = 'verifyAccount', redirectUrl = null, dismissable = true) => {
      setModalView(view)
      setRedirect(redirectUrl)
      setDismissable(dismissable)
      setModalIsOpen(true)
    },
    []
  )

  const closeModal = useCallback(() => {
    setRedirect(null)
    setModalIsOpen(false)
  }, [])

  const clearUser = useCallback(() => {
    setUserId(null)
    clearPersistedAuth(ellisIslandClientId)

    // This is the suggested method for clearing SWR cache
    mutate(() => true, undefined, false)

    SegmentHandler.track('Auth Completed', {
      location: router.asPath,
      value: 'logout',
    })
  }, [router, mutate, ellisIslandClientId])

  const persistUser = useCallback(
    (
      userId = '',
      token = '',
      refreshToken: string | null = null,
      redirectUrl: string | null = null
    ) => {
      if (!userId || !token) {
        clearUser()
        throw new Error('Unable to authenticate. Please try logging-in again.')
      }
      setUserId(userId)
      setPersistedAuth(userId, token, refreshToken)

      if (redirectUrl) {
        Router.push(redirectUrl)
        setRedirect(null)
      }

      SegmentHandler.track('Auth Completed', {
        location: router.asPath,
        value: 'login',
      })
    },
    [setUserId, router, clearUser]
  )

  // Restore user on page load
  useEffect(() => {
    const { userId } = getPersistedAuth()
    setUserId(userId || null)
  }, [])

  useAuthTokenRefresher({ enabled: !!userId })

  if (userError && userRetryCount > 1) {
    return (
      <div className="flex h-full flex-1 flex-col items-center justify-center p-6 text-center">
        <Type as="p" variant="paragraph-md" className="max-w-lg">
          We encountered an error with your account. Please logout below and try
          again.
        </Type>

        <Button className="mt-4" onClick={clearUser}>
          Logout
        </Button>
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        startAuth,
        modalIsOpen,
        openModal,
        closeModal,
        modalView,
        setModalView,
        persistUser,
        clearUser,
        redirect,
        dismissable,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
