import { memo, useEffect } from 'react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { report } from '@/utils'
import { getGrowthbook, trackExperimentExposure } from '../utils'

const growthbook = getGrowthbook({
  trackingCallback: (experiment, result) => {
    trackExperimentExposure(experiment, result, SegmentHandler.track)
  },
})

interface Props {
  children: React.ReactNode
}

const Provider: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    growthbook.init().catch(() => {
      report.warn('Growthbook Failed to Load Features - Retrying 1 time')
      growthbook.init().catch(() => {
        report.error('Growthbook Failed to Load Features on Retry')
      })
    })
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}

export const ExperimentContextProvider = memo(Provider)
