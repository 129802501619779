import React, { useCallback, Dispatch, SetStateAction } from 'react'
import { Eye, EyeSlash } from '@/components/svg'
import ButtonBase from '../../ButtonBase'

interface Props {
  name: string
  isSecretMasked: boolean
  setSecretMasked: Dispatch<SetStateAction<boolean>>
}

const FormFieldSecretToggle: React.FC<Props> = ({
  name,
  isSecretMasked,
  setSecretMasked,
}) => {
  const toggleSecret = useCallback(() => {
    setSecretMasked((cur) => {
      const newVal = !cur

      // Focus input after timeout for re-render
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="${name}"]`
        ) as HTMLInputElement
        input?.focus()
      }, 10)

      return newVal
    })
  }, [setSecretMasked, name])

  return (
    <ButtonBase
      onClick={toggleSecret}
      type="button"
      className="absolute right-2 top-[50%] -translate-y-[50%] focus:ring-1 focus:ring-oxide"
      aria-label="Toggle secret"
    >
      {isSecretMasked ? (
        <Eye className="w-6 text-oxide" />
      ) : (
        <EyeSlash className="w-6 text-oxide" />
      )}
    </ButtonBase>
  )
}

export default FormFieldSecretToggle
