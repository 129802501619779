import { ReactNode } from 'react'
import classnames from 'classnames'

interface Props {
  className?: string
  highlights: (string | ReactNode)[]
}

const Highlights = ({ className, highlights }: Props) => {
  return (
    <ol className={classnames('m-3 mb-8 [counter-reset:li] md:m-0', className)}>
      {highlights.map((message, index) => (
        <li
          key={`highlight-${index}`}
          className={classnames(
            'mb-5 flex flex-row items-center',
            'before:mr-3 before:flex before:h-5 before:w-5 before:items-center before:justify-center before:rounded-full before:bg-white before:p-5 before:font-whitney before:text-lg before:shadow before:content-[counter(li)] before:[counter-increment:li]'
          )}
        >
          {message}
        </li>
      ))}
    </ol>
  )
}

export default Highlights
