import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { usePopper } from 'react-popper'
import { TriangleDown } from '@/components/svg'
import { formUtil } from '@/utils'
import Portal from '../Portal'
import Text from '../Text/Text'
import { FormInput, Option } from '../form/types'

const PopoverBeacon = ({ onClose }: { onClose?: () => void }) => {
  const onCloseRef = useRef(onClose)

  useEffect(() => {
    window.scrollTo(window.scrollX, window.scrollY + 1)
    window.scrollTo(window.scrollX, window.scrollY - 1)
    const ref = onCloseRef.current
    return () => {
      ref?.()
    }
  }, [])

  return null
}

export interface Props extends FormInput {
  name: string
  options: Option[] | Option[][]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onClose?: () => void
  disabled?: boolean
  selectClassName?: string
}

const Select: React.FC<Props> = ({
  name,
  options,
  value,
  onChange,
  onClose,
  disabled,
  className,
  selectClassName,
}) => {
  const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null)
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes, state } = usePopper(targetRef, popperRef, {
    placement: 'bottom-start',
  })

  const selected = options
    .flat()
    .find(({ value: optionValue }) => optionValue === value)

  const grouped: Option[][] = formUtil.getGroupedOptions(options)

  const handleChange = useCallback(
    (value) => {
      const event = {
        target: { name, value },
      } as React.ChangeEvent<HTMLSelectElement>
      onChange(event)
    },
    [name, onChange]
  )

  return (
    <div
      className={classNames(
        {
          'opacity-50': disabled,
        },
        className
      )}
    >
      <Listbox
        value={selected?.value}
        onChange={handleChange}
        disabled={disabled}
      >
        <Listbox.Button
          ref={setTargetRef}
          className={classNames(
            'flex w-full items-center bg-core-white text-left focus:outline-none',
            selectClassName
          )}
        >
          <div
            data-value={selected?.value}
            id={name}
            // Allow adding a name attr to this div as it contains the current value
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name={name}
            aria-labelledby={`${name}-label`}
            className="truncate pr-2"
          >
            {selected?.label}
          </div>
          <TriangleDown
            className="ml-auto shrink-0 text-gray"
            style={{ width: 10, height: 5 }}
            aria-hidden="true"
          />
        </Listbox.Button>

        <Portal popoverRef={targetRef}>
          <div
            ref={setPopperRef}
            style={{
              ...styles.popper,
              width: state?.rects?.reference?.width,
              zIndex: 9999,
            }}
            {...attributes.popper}
          >
            <Listbox.Options as="div">
              {({ open }) => (
                <div
                  className={classNames({
                    'relative font-whitney': true,
                    'pointer-events-auto opacity-100': open,
                    'pointer-events-none opacity-0': !open,
                  })}
                >
                  <PopoverBeacon onClose={onClose} />
                  <div className="mt-1 max-h-[40vh] overflow-auto rounded-sm border border-gray-8 bg-white py-3 shadow-section focus:outline-none">
                    {grouped.map((group, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <ul>
                            {group.map(({ label, value }) => (
                              <Listbox.Option key={value} value={value}>
                                {({ selected, active }) => {
                                  return (
                                    <Text
                                      as="div"
                                      preset="body.lg"
                                      className={classNames({
                                        'relative flex w-full cursor-default items-center px-6 py-2':
                                          true,
                                        'bg-core-gray-100': active || selected,
                                      })}
                                      data-value={value}
                                    >
                                      {label}
                                    </Text>
                                  )
                                }}
                              </Listbox.Option>
                            ))}
                          </ul>

                          {idx < grouped.length - 1 && (
                            <hr className="border-t-1 mx-3 my-1 border-b-0 border-gray-8" />
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              )}
            </Listbox.Options>
          </div>
        </Portal>
      </Listbox>
    </div>
  )
}

export default Select
