export const scrollToEl = (
  el: HTMLElement | null,
  margin = 0,
  behavior: 'smooth' | 'auto' | undefined
) => {
  if (!el) return

  const { top } = el.getBoundingClientRect()

  window.scrollTo({
    top: window.scrollY + top - margin,
    behavior,
  })
}

export const scrollToNamedEl = (name: string) => {
  const selector = `[name="${name}"]`
  const element = document.querySelector(selector)

  if (element) {
    // Scroll to parent in case element is a hidden field
    scrollToEl(element.parentElement, window.innerHeight / 2, 'smooth')
  }
}

// TODO: scrollToEl should be replaced with this
export const scrollNamedElementIntoView = (
  name: string,
  opts: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' }
) => {
  const selector = `[name="${name}"]`
  const element = document.querySelector(selector) as HTMLElement

  const scrollElement =
    element?.getAttribute('type') === 'hidden' ||
    element?.style?.display === 'none'
      ? element.parentElement
      : element

  if (scrollElement) {
    scrollElement.scrollIntoView(opts)
  }
}

// This scrolls the element, instead of the window
export const scrollToBottomOfElement = (element: HTMLDivElement) => {
  element.scrollTo({
    top: element.scrollHeight,
    behavior: 'smooth',
  })
}
