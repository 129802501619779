import React from 'react'
import classNames from 'classnames'
import ButtonBase, { Props as BaseProps } from '../ButtonBase/ButtonBase'
import config from './config'

export type Variant = keyof typeof config

export type Color<T extends Variant> = keyof (typeof config)[T]

interface CommonProps extends Omit<BaseProps, 'styled'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  rounded?: boolean
  disabled?: boolean
  pill?: boolean
}

export interface VariantButton<T extends Variant> extends CommonProps {
  variant?: T
  color?: T extends Variant ? Color<T> : never
}

export type Props =
  | VariantButton<'primary'>
  | VariantButton<'secondary'>
  | VariantButton<'text'>
  | VariantButton<'link'>

const Button: React.FC<Props> = ({
  variant = 'primary',
  color = 'oxide',
  size = 'md',
  rounded = true,
  disabled = false,
  pill = false,
  className,
  ...rest
}) => {
  // TODO: typescript warning about invalid variant/color combos
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colorClassName = config[variant]?.[color]

  return (
    <ButtonBase
      {...rest}
      styled
      className={classNames(
        {
          'leading-tight focus:outline-none focus-visible:ring-1': true,
          'tracking-wide': size !== 'xs' && size !== 'sm',
          'transition-all duration-150 disabled:pointer-events-none': true,
          'h-14 px-7': size === 'lg' && variant !== 'link',
          'h-12 px-6': size === 'md' && variant !== 'link',
          'h-10 px-5': size === 'sm' && variant !== 'link',
          'h-8 px-4': size === 'xs' && variant !== 'link',
          rounded: !pill && rounded,
          'rounded-full': pill,
          'text-lg': size === 'lg',
          'text-sm': size === 'sm',
          'text-xs': size === 'xs',
          'font-semibold': variant === 'link',
        },
        [className, colorClassName]
      )}
      disabled={disabled}
    />
  )
}

export default Button
