interface GenericObject {
  [key: string]: any
}

// remove empty strings and empty objects, while preserving falsey values like false, 0, and null
export const cleanObject = (object: any, isRecursive = true): GenericObject => {
  const clean: GenericObject = {}
  Object.entries(object).forEach(([key, value]) => {
    if (
      isRecursive &&
      Object.prototype.toString.call(value) === '[object Object]'
    ) {
      const innerClean: GenericObject = cleanObject(value, isRecursive)
      if (Object.keys(innerClean).length) {
        clean[key] = innerClean
      }
      return
    }
    if (value !== '') {
      clean[key] = value
    }
  })
  return clean
}
