import client from '../thinMintClient'
import { resolveTipaltiDown } from './resolver'
import { ServerTipalti } from './types'

const baseEndpoint = '/tipalti-url'

export const getTipaltiIframeUrl = (investmentId: string) => {
  return client
    .get(`${baseEndpoint}/${investmentId}/`)
    .then((res: { data: ServerTipalti }) => {
      return resolveTipaltiDown(res.data)
    })
}

export default {
  baseEndpoint,
  getTipaltiIframeUrl,
}
