import { useEffect, useRef } from 'react'
import { useFormikContext, FormikProps, FormikValues } from 'formik'
import { formUtil } from '@/utils'

// Place this inside a Formik component and it call the handler when values change

interface Props {
  handler: (values: FormikValues) => void
  formRef: { current: FormikProps<FormikValues> | null }
}

const FormObserver: React.FC<Props> = ({ handler, formRef }) => {
  const { dirty, values } = useFormikContext<FormikValues>()
  const lastValues = useRef<FormikValues>()

  useEffect(() => {
    if (!lastValues.current) {
      lastValues.current = formRef.current?.values
      return
    }

    if (dirty && !formUtil.valuesAreEqual(values, lastValues.current)) {
      handler(values)
    }
  }, [dirty, values, formRef, handler])

  return null
}

export default FormObserver
