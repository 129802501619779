import React from 'react'
import classNames from 'classnames'

const variants = [
  'display',
  'heading-xl',
  'heading-lg',
  'heading-md',
  'heading-sm',
  'heading-xs',
  'title-lg',
  'title-md',
  'title-sm',
  'title-xs',
  'title-xxs',
  'eyebrow-md',
  'eyebrow-sm',
  'paragraph-xl',
  'paragraph-lg',
  'paragraph-md',
  'paragraph-sm',
  'caption-lg',
  'caption-md',
  'caption-sm',
  'footer',
] as const

type BaseVariant = (typeof variants)[number]
type SmVariant = `sm:${BaseVariant}`
type MdVariant = `md:${BaseVariant}`
type LgVariant = `lg:${BaseVariant}`
type Lg2Variant = `2lg:${BaseVariant}`
type XlVariant = `xl:${BaseVariant}`
export type Variant =
  | BaseVariant
  | SmVariant
  | MdVariant
  | LgVariant
  | Lg2Variant
  | XlVariant

export type Props<T extends React.ElementType> = {
  as: T
  variant: Variant | Variant[]
} & React.ComponentPropsWithoutRef<T>

function Type<T extends React.ElementType>({
  as: Tag,
  variant,
  className,
  ...rest
}: Props<T>) {
  // TODO: complains about props not assignable to "LibraryManagedAttributes"
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tag className={classNames(variant, className)} {...rest} />
}

export default Type
