import { useState, useEffect } from 'react'
import {
  FacebookCircle,
  TwitterCircle,
  EmailCircle,
} from '../../components/svg'
import useSiteAssets from './useSiteAssets'

const useCampaignShare = ({ campaign }) => {
  const [url, setUrl] = useState('')
  const { domain } = useSiteAssets()
  const subject = encodeURIComponent(`Checkout ${campaign.name} on ${domain}!`)
  const body = encodeURIComponent(url)

  useEffect(() => {
    setUrl(`${window.location.origin}/${campaign.slug}?utm_medium=share_button`)
  }, [campaign.slug])

  const items = [
    {
      Icon: FacebookCircle,
      label: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      Icon: TwitterCircle,
      label: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=${url}`,
    },
    {
      Icon: EmailCircle,
      label: 'Email',
      href: `mailto:?subject=${subject}&body=${body}`,
    },
  ]

  return items
}

export default useCampaignShare
