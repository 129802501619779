import React, { useState, useCallback } from 'react'
import ToastNotificationList from '@/components/notifications/ToastNotification/ToastNotificationList'
import BusyNotificationWrap from '../../notifications/BusyNotification/BusyNotificationWrap'
import NotificationsContext, {
  Notification,
  NotificationType,
} from './NotificationsContext'

interface Props {
  children: React.ReactNode
}

const allowsMultiple = (item: Notification) =>
  ['toast', 'bell'].includes(item.type)

const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const addNotification = useCallback(
    (item: Notification) => {
      setNotifications((cur) => {
        // If this notification type doesn't allow multiple instances, we need to replace
        // an existing notification of this type if it exists
        const appending = allowsMultiple(item)
        const idx = cur.findIndex((n) => n.type === item.type)
        if (!appending && idx > -1) {
          const arr = [...cur]
          arr[idx] = item
          return arr
        }
        // We don't want to allow duplicates of the exact same notification.
        const idk = cur.findIndex((k) => k.id === item.id)
        if (idk > -1) {
          const arr = [...cur]
          arr[idk] = item
          return arr
        }

        // Otherwise, append to the current array
        return [...cur, item]
      })
    },
    [setNotifications]
  )

  const updateNotification = useCallback(
    (id: string | number, item: Partial<Notification>) => {
      setNotifications((cur) => {
        // If notification is present, then update
        const idx = cur.findIndex((n) => n.id === id)
        if (idx > -1) {
          const arr = [...cur]
          // TODO: typescript worried that patch object might not match
          // the the typings of the found notification object
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          arr[idx] = { ...arr[idx], ...item }
          return arr
        }

        return cur
      })
    },
    [setNotifications]
  )

  const removeNotification = useCallback(
    (id: string | number) => {
      setNotifications((cur) => cur.filter((n) => n.id !== id))
    },
    [setNotifications]
  )

  const clearNotifications = useCallback(
    (type?: NotificationType) => {
      setNotifications(type ? notifications.filter((n) => n.type !== type) : [])
    },
    [notifications]
  )

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotification,
        updateNotification,
        removeNotification,
        clearNotifications,
      }}
    >
      <BusyNotificationWrap />
      <ToastNotificationList />
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
