import useHydration from '@/services/hooks/useHydration'
import PageSpinner from './PageSpinner'

const PageLoader = () => {
  const { isHydrated } = useHydration()

  if (!isHydrated) {
    return null
  }

  return (
    <>
      <div className="h-[100vh] flex-1" />
      <PageSpinner active />
    </>
  )
}

export default PageLoader
