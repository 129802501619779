import React from 'react'
import { Popover as UIPopover } from '@headlessui/react'
import Router, { useRouter } from 'next/router'
import { Text, ButtonBase } from '@/components/common'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useAuth from '@/services/hooks/useAuth'
import useIssuers from '@/services/hooks/useIssuers'
import useNotifications from '@/services/hooks/useNotifications'

export interface Props {
  className?: string
}

const linkClassName =
  'block w-full py-2 px-3 hover:bg-oxide-30 rounded transition ease-in-out text-left'

const UserMenuLoggedIn: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const { issuers = [] } = useIssuers()
  const { user, clearUser } = useAuth()
  const { clearNotifications } = useNotifications()

  return (
    <div className={className}>
      <UIPopover.Button as="div">
        <ButtonBase
          className={linkClassName}
          onClick={() => Router.push('/investments')}
        >
          <Text as="span" preset="body.md">
            My Investments
          </Text>
        </ButtonBase>
      </UIPopover.Button>

      <div className="flex flex-col">
        {issuers.map(({ id, name }) => (
          <UIPopover.Button as="div" key={id}>
            <ButtonBase
              className={linkClassName}
              onClick={() => Router.push(`/issuer/${id}/dashboard`)}
            >
              <Text as="span" preset="body.md">
                {issuers.length > 1 ? `${name} Dashboard` : 'Issuer Dashboard'}
              </Text>
            </ButtonBase>
          </UIPopover.Button>
        ))}

        {user?.isSuperuser && (
          <UIPopover.Button as="div">
            <ButtonBase
              className={linkClassName}
              onClick={() => Router.push(`/issuer/admin/dashboard`)}
            >
              <Text as="span" preset="body.md">
                Funding Dashboard
              </Text>
            </ButtonBase>
          </UIPopover.Button>
        )}
      </div>

      <UIPopover.Button as="div">
        <ButtonBase
          className={linkClassName}
          onClick={() => {
            clearUser()
            clearNotifications()

            SegmentHandler.track('On Tap', {
              location: router.asPath,
              section_name: 'header',
              element_name: 'menu_item',
              value: 'Log Out',
              action_type: 'tap',
            })
          }}
        >
          <Text as="span" preset="body.md" className="font-bold">
            Log Out
          </Text>
        </ButtonBase>
      </UIPopover.Button>
    </div>
  )
}

export default UserMenuLoggedIn
