interface Obj {
  [key: string]: any
}

type NonObject = string | number | boolean | null | undefined

export function parseObject(val: Obj, fn: (val: string) => string): Obj
export function parseObject(
  val: NonObject,
  fn: (val: string) => string
): NonObject
export function parseObject(val: any, fn: (val: string) => string) {
  if (Array.isArray(val)) {
    return val.map((v) => (v && typeof v === 'object' ? parseObject(v, fn) : v))
  }

  if (typeof val !== 'object' || val === null || val === undefined) {
    return val
  }

  const newObj: Obj = {}

  for (const d in val) {
    if (Object.prototype.hasOwnProperty.call(val, d)) {
      newObj[fn(d)] =
        val[d] && typeof val[d] === 'object'
          ? parseObject(val[d] as Obj, fn)
          : val[d]
    }
  }

  return newObj
}

export const snakeToCamelStr = (str: string) =>
  str.replace(/(_\w)/g, (k) => k[1].toUpperCase())

export const snakeToCamel = (obj: Obj) => parseObject(obj, snakeToCamelStr)

export const camelToSnakeStr = (str: string) =>
  str.replace(/[A-Z]/g, (k) => `_${k.toLowerCase()}`)

export const camelToSnake = (obj: Obj) => parseObject(obj, camelToSnakeStr)
