import { FormikValues } from 'formik'
import { Option } from '@/components/common/form/types'
import { scrollNamedElementIntoView } from '../scrollUtil/index'

export const lookupValue = (values: FormikValues, key: string) => {
  const parts = key.split('.')
  return parts.reduce(
    (acc: FormikValues | string | undefined, part: string) =>
      typeof acc === 'object' ? acc[part] : undefined,
    values
  )
}

export const updateValues = (
  values: FormikValues,
  key: string,
  updateValue: string | number | boolean | FormikValues
) => {
  const parts = key.split('.')
  const updated = { ...values }

  parts.reduce((acc: FormikValues, part: string, idx: number) => {
    if (idx === parts.length - 1) acc[part] = updateValue
    if (!acc[part]) acc[part] = {}
    return acc[part]
  }, updated)

  return updated
}

type MaybeArray = Option | Option[]
export const getGroupedOptions = (options: MaybeArray[]): Option[][] => {
  const grouped: Option[][] = options.every((item) => !Array.isArray(item))
    ? [options as Option[]]
    : (options as Option[][])

  return grouped.filter((arr) => arr.length > 0)
}

export const valuesAreEqual = (
  a: FormikValues | string | number | undefined,
  b: FormikValues | string | number | undefined
) => {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (a instanceof Array && b instanceof Array) {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (!valuesAreEqual(a[i], b[i])) return false
    }
    return true
  }
  if (a instanceof Object && b instanceof Object) {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    if (aKeys.length !== bKeys.length) return false
    for (let i = 0; i < aKeys.length; i++) {
      const key = aKeys[i]
      if (!valuesAreEqual(a[key], b[key])) return false
    }
    return true
  }
  return false
}

export const getServerFieldErrors = (
  formValues: FormikValues,
  errorObj?: { [key: string]: string[] }
) => {
  if (!errorObj) return null

  const errors = Object.entries(errorObj).filter(
    ([key, value]) =>
      Object.keys(formValues).includes(key) && Array.isArray(value)
  )

  return errors.length > 0
    ? (errors.reduce(
        (acc, [key, val]) => ({ ...acc, [key]: val.join(', ') }),
        {}
      ) as { [key: keyof FormikValues]: string })
    : null
}

export const handleServerFieldErrors = (
  errorObj: { [key: string]: string },
  setFieldError: (name: string, error: string) => void,
  prefix?: string
) => {
  if (Object.keys(errorObj).length === 0) return

  const entries = Object.entries(errorObj)

  // display error message on each field with error
  entries.forEach(([name, error]) => {
    setFieldError(prefix ? `${prefix}.${name}` : name, error)
  })

  // scroll to first field with error
  scrollNamedElementIntoView(
    prefix ? `${prefix}.${entries[0][0]}` : entries[0][0]
  )
}

interface ServerFieldError {
  errorObj: { [key: string]: string | string[] }
  serverFieldName: string // should be snake_cased server field name
  formFieldName: string // should be camelCased form field name
  setFieldError: (name: string, error: string) => void
}
export const handleNamedServerFieldError = ({
  errorObj,
  serverFieldName,
  formFieldName,
  setFieldError,
}: ServerFieldError) => {
  // if server fieldName exists on error object, then mark the associated
  // form field with an error and scroll to it
  const serverError = errorObj[serverFieldName]
  if (errorObj[serverFieldName]) {
    const errorStr = Array.isArray(serverError) ? serverError[0] : serverError
    setFieldError(formFieldName, errorStr)
    scrollNamedElementIntoView(formFieldName)
    return errorStr
  }

  return null
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
