import { ElementType, useRef } from 'react'
import {
  arrow,
  autoPlacement,
  useFloating,
  FloatingArrow,
  Placement,
  offset,
  autoUpdate,
  flip,
  shift,
} from '@floating-ui/react'
import { Popover as UIPopover } from '@headlessui/react'
import classNames from 'classnames'
import Portal from '../Portal'

const ARROW_HEIGHT = 7
const GAP = 2

export interface Props {
  as?: ElementType
  alwaysRender?: boolean
  children: React.ReactNode
  className?: string
  content: JSX.Element
  autoFlip?: boolean
  label?: string
  openOverride?: boolean
  placement?: Placement
  theme?: 'light' | 'dark'
}

const Popover = ({
  as,
  alwaysRender,
  children,
  className,
  content,
  autoFlip,
  label,
  openOverride,
  placement,
  theme = 'dark',
}: Props) => {
  const arrowRef = useRef(null)
  const { refs, floatingStyles, context } = useFloating({
    placement,
    middleware: [
      !placement && !autoFlip && autoPlacement(),
      autoFlip && flip(),
      arrow({ element: arrowRef }),
      offset(ARROW_HEIGHT + GAP),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  })

  return (
    <UIPopover className={className}>
      {({ open }) => {
        const isOpen = openOverride === undefined ? open : openOverride

        return (
          <>
            <UIPopover.Button
              as={as}
              ref={refs.setReference}
              aria-label={label}
              className="block outline-none active:opacity-75"
            >
              {children}
            </UIPopover.Button>

            <Portal>
              <UIPopover.Panel
                static={alwaysRender || openOverride}
                ref={refs.setFloating}
                style={floatingStyles}
                className={classNames({
                  'z-[9998] transition-opacity duration-150': true,
                  'pointer-events-auto opacity-100': isOpen,
                  'pointer-events-none opacity-0': !isOpen,
                })}
                aria-label="Popover Menu"
              >
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  className={classNames({
                    'fill-core-white': theme === 'light',
                    'fill-core-gray-900': theme === 'dark',
                  })}
                  height={ARROW_HEIGHT}
                />
                <div
                  className={classNames('max-w-[320px] rounded', {
                    'bg-core-white text-core-gray-900 shadow-section':
                      theme === 'light',
                    'bg-core-gray-900 text-core-white': theme === 'dark',
                  })}
                >
                  <div className="max-h-[50vh] overflow-y-auto">{content}</div>
                </div>
              </UIPopover.Panel>
            </Portal>
          </>
        )
      }}
    </UIPopover>
  )
}

export default Popover
