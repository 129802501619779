import React, { ComponentProps, useMemo } from 'react'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import ExpandableType from '../ExpandableType'
import TextBlock, { Props as TextBlockProps } from '../TextBlock/TextBlock'
import Type from '../Type'

type Props = TextBlockProps &
  Omit<ComponentProps<typeof ExpandableType>, 'children'> & {
    typeVariant?: ComponentProps<typeof Type>['variant']
  }

const ExpandableTextBlock: React.FC<Props> = ({
  lines,
  document,
  className,
  typeVariant = 'paragraph-md',
}) => {
  const optionsOverrides = useMemo(
    () => ({
      [BLOCKS.PARAGRAPH]: (...args: Parameters<NodeRenderer>) => {
        const [, children] = args
        return (
          <Type as="p" variant={typeVariant} className="inline">
            {children}
          </Type>
        )
      },
    }),
    [typeVariant]
  )

  return (
    <ExpandableType lines={lines} className={className}>
      <TextBlock
        document={document}
        optionOverrides={optionsOverrides}
        className="!mb-0"
      />
    </ExpandableType>
  )
}

export default ExpandableTextBlock
