import React, { CSSProperties, useMemo } from 'react'
import { Asset } from 'contentful'
import NextImage, { ImageProps } from 'next/image'
import qs from 'qs'

// Contentful Docs: https://www.contentful.com/developers/docs/references/images-api/
interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  className?: string
  containerClassName?: string
  focus?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  height?: number
  image: Asset
  lazy?: boolean
  style?: CSSProperties
  width?: number
}

const Image: React.FC<Props> = ({
  className,
  containerClassName,
  focus,
  fit,
  height: setHeight,
  image,
  lazy,
  style,
  width: setWidth,
  ...props
}) => {
  const alt = image.fields.description || ''
  const { details, url } = image.fields?.file || {}
  const params = qs.stringify({
    f: focus,
    fit,
  })

  // default 16:9 ratio
  let widthRatio = 16 / 9
  let heightRatio = 9 / 16
  if (setHeight && setWidth) {
    widthRatio = setWidth / setHeight
    heightRatio = setHeight / setWidth
  } else if (details.image) {
    widthRatio = details.image.width / details.image.height
    heightRatio = details.image.height / details.image.width
  }

  const [height, width] = useMemo(() => {
    if (setHeight || setWidth) {
      const calcHeight = setHeight || Number(setWidth) * heightRatio
      const calcWidth = setWidth || Number(setHeight) * widthRatio
      return [calcHeight, calcWidth]
    }

    return [details.image?.height, details.image?.width]
  }, [details.image, setHeight, setWidth, heightRatio, widthRatio])

  // NextImage generates widths for 1x and 2x images, but we also want to provide correct height values to Contentful's image API, so we calculate them here
  const imageLoader = ({ src = '', width = 4000, quality = 75 }) => {
    const height = Math.round(width * heightRatio)
    return `https:${src}?h=${height}&w=${width}&q=${quality}&fm=webp&${params}`
  }

  return (
    <div className={containerClassName} style={style}>
      <NextImage
        alt={alt}
        className={className}
        height={height}
        loading={lazy ? 'lazy' : 'eager'}
        loader={imageLoader}
        src={url}
        width={width}
        {...props}
      />
    </div>
  )
}

export default Image
