import axios from 'axios'
import client from '../thinMintClient'
import { resolveUserDown } from './resolver'
import { ServerUser, User } from './types'

export const baseEndpoint = '/users'

export const updateFinancialTies = (
  related_campaigns: string[],
  userId: string
) =>
  client
    .patch<ServerUser>(`${baseEndpoint}/${userId}/`, { related_campaigns })
    .then((res) => resolveUserDown(res.data))

export const updateUserName = (
  userId: string,
  first_name: string,
  last_name: string
) =>
  client
    .patch<ServerUser>(`${baseEndpoint}/${userId}/`, {
      first_name,
      last_name,
    })
    .then((res) => resolveUserDown(res.data))

export const updateUserAgreedTerms = (userId: string) =>
  client
    .post<ServerUser>(`${baseEndpoint}/${userId}/agree_to_investor_terms/`)
    .then((res) => resolveUserDown(res.data))

export const initializeInvestmentTestData = (userId: string) => {
  return client.post<Record<string, unknown>>(
    `${baseEndpoint}/${userId}/generate-test-investments/`
  )
}

export const getUser = (userId: string) =>
  client
    .get<ServerUser>(`${baseEndpoint}/${userId}/`)
    .then((res) => resolveUserDown(res.data) as User)

export const verifyAccountExists = async (email: string) => {
  try {
    const response = await client.post(`${baseEndpoint}/exists/`, { email })
    // 200 is expected if account exists
    return response?.status === 200
  } catch (error) {
    // 404 is expected if account doesn't exist
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return false
    }
    throw error
  }
}
