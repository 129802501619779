import { useEffect } from 'react'
import { useFormikContext, FormikValues } from 'formik'
import { scrollUtil } from '@/utils'

// Place this inside a Formik component and it will scroll
// to the first error input when submit is called

const ScrollToError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const getFirstError = (obj: FormikValues, prefix = ''): string | null => {
        const key = Object.keys(obj)[0]
        if (typeof obj[key] === 'object') {
          return getFirstError(obj[key], `${key}.`)
        }

        return key ? `${prefix}${key}` : null
      }

      const firstError = getFirstError(errors)
      if (firstError) scrollUtil.scrollNamedElementIntoView(firstError)
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default ScrollToError
