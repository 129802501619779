import React from 'react'
import { Campaign } from '@/services/api/campaign'
import { formatMoney, progressUtil } from '@/utils'
import Type from '../Type'
import { MONEY_FORMAT } from './utils'

interface Props {
  campaign: Campaign
}

const Progress: React.FC<Props> = ({ campaign }) => {
  return (
    <div>
      {campaign.capitalRaised >= campaign.minimumCapitalGoal &&
        campaign.stretchGoal && (
          <Type as="p" variant="caption-sm" className="mb-2 text-core-gray-900">
            {campaign.stretchGoal.title}
          </Type>
        )}

      <div className="mb-2 h-2 w-full rounded-lg bg-core-gray-200">
        <div
          // needs min, or it looks bad with rounded corners
          className="h-full min-w-[.5rem] rounded-lg bg-core-oxide"
          style={{
            width: progressUtil.getProgressWidth(campaign.progress),
          }}
        />
      </div>

      <Type as="p" variant="title-xs">
        <span className="font-bold">
          {formatMoney(campaign.amountRaised, MONEY_FORMAT)}
        </span>{' '}
        {campaign.progressWithGoalString}
      </Type>
    </div>
  )
}

export default Progress
