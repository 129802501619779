import useSWR, { SWRConfiguration, Key } from 'swr'

interface Props<T> {
  key: Key
  request: () => Promise<T>
  config?: SWRConfiguration
}

function useApi<T>({ key, request, config }: Props<T>) {
  const { data, error, ...rest } = useSWR<T>(key, request, {
    errorRetryCount: 0,
    ...config,
  })

  return {
    data,
    error,
    isError: error, // TODO: this should be a boolean; `error` object included in rest if needed
    ...rest,
  }
}

export default useApi
