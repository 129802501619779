import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import classnames from 'classnames'
import { ISocialShareBlockFields } from 'src/types/generated/contentful'
import TextBlock from '@/components/common/TextBlock'
import Image from '@/components/contentful/Image'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import { contentUtil } from '@/utils'
import SocialLink from '../SocialLink'
import Type from '../Type'

/* eslint-disable @typescript-eslint/no-explicit-any */
const textOverrides = {
  [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
    return (
      <Type as="p" variant="paragraph-sm" className="leading-relaxed">
        {children}
      </Type>
    )
  },
  [BLOCKS.HEADING_4]: (node: any, children: any) => {
    return (
      <Type as="h4" variant="title-xs" className="mb-1">
        {children}
      </Type>
    )
  },
}
/* eslint-enable @typescript-eslint/no-explicit-any */

interface Props {
  campaignName: string
  campaignSlug: string
  className?: string
  content: ISocialShareBlockFields
}

const CampaignShareBox: React.FC<Props> = ({
  campaignName,
  campaignSlug,
  content,
  className,
}) => {
  const { links, text } = content
  const { campaignAssets } = useCampaignAssets(campaignSlug)
  const { image, slug = '' } = campaignAssets || {}

  return (
    <div
      className={classnames(
        'w-full items-start rounded-lg border border-core-gray-200 bg-white px-6 pb-6 pt-4 md:flex md:px-4 md:pb-4',
        className
      )}
    >
      {image && (
        <div className="mb-4 md:mb-0 md:mr-4 md:flex-[0_0_224px]">
          <Image image={image} className="w-full rounded-lg" width={534} />
        </div>
      )}
      <div>
        {text && (
          <TextBlock
            document={text}
            className="text-left md:mb-3"
            optionOverrides={textOverrides}
          />
        )}
        <div className="flex flex-wrap gap-2">
          {links.map((rawLink) => {
            const link = contentUtil.mapContent(rawLink)
            if (!link) return null

            let emailSubject
            if (link.emailSubject)
              emailSubject = link.emailSubject.replace(
                /{{campaign.name}}/g,
                campaignName
              )

            return (
              <SocialLink
                key={link.id}
                className="flex-grow"
                emailSubject={emailSubject}
                name={link.name}
                icon={link.icon}
                preconfigured={link.preconfigured}
                url={link.url}
                path={slug}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CampaignShareBox
