import React from 'react'
import classNames from 'classnames'
import Text from '@/components/common/Text/Text'
import useCampaignShare from '@/services/hooks/useCampaignShare'

const CampaignShareBox = ({ campaign, className }) => {
  const shareItems = useCampaignShare({ campaign })

  return (
    <div
      className={classNames(
        {
          'flex items-center  p-6': true,
          'rounded border border-gray-8 bg-core-gray-100': true,
        },
        className
      )}
    >
      <Text as="p" preset="body.md" className="mr-auto pr-4 text-black">
        Share this project
      </Text>

      <div className="flex">
        {shareItems.map(({ Icon, href, label }, idx) => (
          <a
            key={idx}
            href={href}
            title={`Share on ${label}`}
            target="_blank"
            rel="noreferrer"
            className={idx === shareItems.length - 1 ? '' : 'mr-2'}
          >
            <Icon width={35} height={35} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default CampaignShareBox
