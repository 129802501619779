import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { Info } from '@/components/svg'
import Type from '../Type'
import Popover, { Props as PopoverProps } from './Popover'

// export preferred style for TextBlock
export const popupOverride = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
    return (
      <Type as="p" variant="paragraph-sm" className="mb-4 last:mb-0">
        {children}
      </Type>
    )
  },
}

interface Props extends Omit<PopoverProps, 'children'> {
  title?: string
}

const InfoPopover = ({ content, title, ...rest }: Props) => (
  <Popover
    {...rest}
    content={
      <div className="p-3 text-sm font-light">
        {title && (
          <Type as="p" variant="title-xs" className="mb-3 mr-6 font-semibold">
            {title}
          </Type>
        )}
        {content}
      </div>
    }
  >
    <Info className="relative h-[1.1em] w-[1.1em]" />
  </Popover>
)

export default InfoPopover
