import React from 'react'
import classNames from 'classnames'
import Type from '@/components/common/Type'
import FormField from '@/components/common/form/FormField'
import { CheckCircle } from '@/components/svg'
import { Perk } from '@/services/api/campaign'
import { SnakeToCamel } from '@/services/api/types'
import { formatMoney } from '@/utils'

interface Props {
  className?: string
  nameInCredits?: string
  perk: SnakeToCamel<Perk>
  qualified?: boolean
  showAvailable?: boolean
}

const PerkCard: React.FC<Props> = ({
  className,
  nameInCredits,
  perk,
  qualified,
  showAvailable,
}) => {
  const {
    claimed,
    descriptionLong,
    descriptionShort,
    limit,
    name,
    qualifyingInvestmentAmount,
  } = perk
  const clampClaimed = limit && claimed !== null ? Math.min(claimed, limit) : 0
  const clampAvailable = limit ? limit - clampClaimed : 0
  const isQualifiedAndWithinLimit =
    (qualified && !limit) || (qualified && limit && clampAvailable > 0)

  return (
    <div
      className={classNames(
        'relative rounded-[4px] border-2 bg-core-white p-4 text-core-gray-900',
        {
          'border-core-oxide': isQualifiedAndWithinLimit,
          'border-core-gray-300': !isQualifiedAndWithinLimit,
        },
        className
      )}
    >
      {isQualifiedAndWithinLimit && (
        <CheckCircle
          className="absolute right-4 top-4 h-5 w-5 text-core-oxide"
          data-testid="qualified-check"
        />
      )}

      {limit && clampAvailable === 0 && (
        <Type
          as="span"
          variant="caption-sm"
          className="mb-2 inline-block rounded-sm bg-core-warning-500 px-1 py-0.5 text-core-gray-950"
        >
          100% claimed
        </Type>
      )}

      <Type
        as="h4"
        variant="title-lg"
        className="mb-1 font-bold leading-normal tracking-tighter"
      >
        {formatMoney(qualifyingInvestmentAmount)} or more
      </Type>
      <Type
        as="p"
        variant="title-sm"
        className="mb-2 font-semibold leading-tight"
      >
        {descriptionShort}
      </Type>
      <Type as="p" variant="paragraph-md" className="text-core-gray-800">
        {descriptionLong}
      </Type>

      {qualified && name.toLowerCase() === 'name in the credits' && (
        // display FormToggleInput for name in the credits
        <div className="mt-4 bg-white py-2">
          <FormField
            displayName="Name to display in credits"
            name="investment.nameInCredits"
          />
          <FormField
            className="mt-4"
            type="toggle"
            name="investment.showNameInCredits"
            text={`Allow the name, ${nameInCredits}, to be included in the credits. Select "NO" to remain anonymous.`}
          />
        </div>
      )}

      {!!limit && (
        <div className="mt-5">
          <Type
            as="span"
            variant="paragraph-md"
            className="mb-2 inline-block rounded-lg bg-core-gray-100 p-1 leading-loose text-core-gray-700"
          >
            {showAvailable
              ? `${clampAvailable.toLocaleString()}/${limit.toLocaleString()} available*`
              : `${clampClaimed.toLocaleString()}/${limit.toLocaleString()} claimed*`}
          </Type>
          <Type as="p" variant="caption-sm" className="text-core-gray-600">
            *This is a limited perk subject to availability. Submitting your
            investment doesn’t ensure you will get this perk.
          </Type>
        </div>
      )}
    </div>
  )
}

export default PerkCard
