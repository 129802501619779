import { casingUtil } from '@/utils'
import {
  resolveBirthday,
  resolveBirthdayPayload,
  resolvePhoneNumber,
} from '../resolvers'
import { Party, PartyForm, ServerParty } from './types'

export const resolvePartyDown = (party: ServerParty) => {
  const {
    accredited_status,
    date_of_birth,
    phone_number,
    country_code,
    income,
    invested_elsewhere_past_year,
    ...rest
  } = party

  return {
    ...casingUtil.snakeToCamel(rest),
    accredited: accredited_status !== 'Not Accredited',
    birthday: resolveBirthday(date_of_birth),
    phoneNumber: resolvePhoneNumber(phone_number),
    country: country_code,
    yearlyIncome: income,
    otherInvestmentsPastYear: invested_elsewhere_past_year,
  } as Party
}

export const resolvePartyUp = ({
  accredited,
  birthday,
  phoneNumber,
  country,
  yearlyIncome,
  otherInvestmentsPastYear,
  ...rest
}: PartyForm) => {
  return {
    ...casingUtil.camelToSnake(rest),
    accredited_status: accredited ? 'Self Accredited' : 'Not Accredited',
    date_of_birth: resolveBirthdayPayload(birthday),
    phone_number: phoneNumber,
    country_code: country,
    income: yearlyIncome,
    invested_elsewhere_past_year: otherInvestmentsPastYear,
  } as ServerParty
}
