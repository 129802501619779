import { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import { identify as FullStoryIdentify } from '@fullstory/browser'
import { useExperimentContext } from '@/experimentation'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useAuth from '@/services/hooks/useAuth'
import { userAgentUtil } from '@/utils'

const Identify = () => {
  const { user } = useAuth()
  const { anonymousId } = SegmentHandler.getState().user
  const { setUserAttributes } = useExperimentContext()

  useEffect(() => {
    if (user) {
      SegmentHandler.identify({ email: user.email }, user.id)
      Bugsnag.setUser(user.id)
      FullStoryIdentify(user.id, {
        email: user.email,
        displayName:
          user.displayName || `${user.firstName} ${user.lastName}`.trim(),
      })
      setUserAttributes({
        userId: user.id,
      })
    }

    // TODO: do we need to un-identify when user === null?
  }, [user, setUserAttributes])

  useEffect(() => {
    setUserAttributes({
      id: anonymousId,
      device: userAgentUtil.detectDevice(),
      isRNWebview: userAgentUtil.isRNWebview(),
    })
  }, [setUserAttributes, anonymousId])

  return null
}

export default Identify
