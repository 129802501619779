import segmentPlugin from '@analytics/segment'
import { NotifiableError } from '@bugsnag/js'
import Analytics from 'analytics'
import { trackSegmentEvent } from '@/services/api/segment'
import { cleanObject, report } from '@/utils'
import { TrackEvents, PageEvent } from './types'

const SegmentAnalytics = Analytics({
  app: 'Angel Funding',
  plugins: [
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    }),
  ],
})

interface AddressTraits {
  city?: string
  country?: string
  postalCode?: string
  state?: string
  street?: string
}

// Some of these are reserved Segment traits:
// https://segment.com/docs/connections/spec/identify/#traits
interface IdentifyTraits {
  accreditedInvestor?: boolean
  address?: AddressTraits
  birthMonth?: string
  birthYear?: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  postalCode?: string
  state?: string
  userAgent?: string
  isRNWebview?: boolean
  device?: 'mobile' | 'tablet' | 'desktop' | 'not available'
}

interface Options {
  suppressSegmentTrack?: boolean
}

const SegmentHandler = {
  ...SegmentAnalytics,
  identify: (traits: IdentifyTraits, id?: string) => {
    if (id) {
      SegmentAnalytics.identify(id, traits && cleanObject(traits))
    } else {
      const {
        user: { userId },
      } = SegmentAnalytics.getState()

      SegmentAnalytics.identify(userId, cleanObject(traits))
    }
  },
  track: async <T extends keyof TrackEvents, R extends TrackEvents[T]>(
    name: T,
    properties: R,
    options?: Options
  ) => {
    const {
      context: { sessionId },
      user: { userId, anonymousId, version },
    } = SegmentAnalytics.getState()

    const resolvedProperties = {
      ...properties,
      version,
      session_id: sessionId,
      anonymous_id: anonymousId,
      user_id: userId,
    }

    if (options?.suppressSegmentTrack) return

    try {
      await trackSegmentEvent(name, resolvedProperties)
    } catch (err) {
      report.error(err as NotifiableError)
    }
  },
  page: (properties: PageEvent) => {
    const {
      context: { sessionId },
      user: { userId, anonymousId, version },
    } = SegmentAnalytics.getState()

    const resolvedProperties = {
      ...properties,
      version,
      session_id: sessionId,
      anonymous_id: anonymousId,
      user_id: userId,
    }

    SegmentAnalytics.page(resolvedProperties)
  },
}

export default SegmentHandler
