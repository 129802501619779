import React from 'react'
import Image from 'next/image'
import Type from '../Type'
import { useGuildLiveCount } from './useGuildLiveCount'

const GuildLiveCount: React.FC = () => {
  const { data, isLoading } = useGuildLiveCount()
  return (
    <div className="bg-core-gray-900 border-2 border-core-copper rounded-lg p-4 mb-6 text-white">
      <div className="flex w-full max-w-[376px] items-center gap-4 mx-auto">
        <Image
          src="https://images.angelstudios.com/image/upload/v1704922101/angel-app/guild/angel-guild-logo-full"
          alt="Angel Guild"
          width={32}
          height={40}
        />
        <Type variant="caption-sm" as="p" className="uppercase">
          Guild Members
          <br />
          Changing the industry
        </Type>
        {!isLoading && data && (
          <Type variant="heading-sm" as="p" className="flex-1 text-right">
            {data.guildMembershipCount.toLocaleString()}
          </Type>
        )}
      </div>
    </div>
  )
}

export default GuildLiveCount
