import React from 'react'
import classNames from 'classnames'
import useMeasure from '@/services/hooks/useMeasure'

interface Props {
  className?: string
  initialHeight?: number
  speed?: 'slow' | 'normal' | 'fast'
}

const HeightTransitioner: React.FC<Props> = ({
  className,
  initialHeight,
  speed = 'normal',
  children,
}) => {
  const [ref, { height = initialHeight }] = useMeasure<HTMLDivElement>()

  return (
    <div className={className}>
      <div
        className={classNames({
          'overflow-hidden transition-[height] ease-in-out': true,
          'duration-500': speed === 'normal',
          'duration-300': speed === 'fast',
          'duration-700': speed === 'slow',
        })}
        style={{ height }}
      >
        <div ref={ref}>{children}</div>
      </div>
    </div>
  )
}

export default HeightTransitioner
