// random shuffle using [Fisher-Yates shuffle](https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle)
export const shuffleArray = (arr: any[]) => {
  const newArr = []
  const arrCopy = [...arr]

  while (arrCopy.length) {
    const randomIndex = Math.floor(Math.random() * arrCopy.length)
    const [element] = arrCopy.splice(randomIndex, 1)

    newArr.push(element)
  }

  return newArr
}
