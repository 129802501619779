import React from 'react'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'
import tailwind from '../../../../tailwind.config'

interface Props {
  active: boolean
  showOverlay?: boolean
}

const PageSpinner: React.FC<Props> = ({ active, showOverlay = false }) => {
  return (
    <div
      className={classNames(
        'fixed inset-0 z-[99999] flex items-center justify-center',
        { 'pointer-events-none': !active }
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 transition-opacity duration-300',
          { 'bg-white bg-opacity-40': showOverlay, 'opacity-0': !active }
        )}
      />
      {active && (
        <div className="relative z-10">
          <Loader
            type="TailSpin"
            color={tailwind.theme.colors.oxide['3']}
            height={75}
            width={75}
          />
        </div>
      )}
    </div>
  )
}

export default PageSpinner
