export const getDisplayName = (
  party?: { firstName: string; lastName: string },
  user?: { firstName?: string; lastName?: string; displayName?: string } | null
) => {
  if (party?.firstName) {
    return `${party.firstName} ${party.lastName}`.trim()
  } else if (user?.displayName) {
    return user.displayName
  } else if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`.trim()
  }

  return 'Anonymous'
}
