const primary = {
  white:
    'bg-core-white hover:bg-core-gray-300 focus-visible:ring-core-gray-600 active:bg-core-gray-600 disabled:bg-core-gray-400 text-core-gray-950 disabled:text-core-gray-600',
  black:
    'bg-core-gray-950 hover:bg-core-gray-800 focus-visible:ring-core-gray-600 active:bg-core-gray-500 disabled:bg-core-gray-400 text-core-white disabled:text-core-gray-600',
  oxide:
    'bg-core-oxide hover:bg-core-oxide-core focus-visible:ring-core-oxide-core active:bg-core-oxide-core disabled:saturate-50 text-core-white disabled:text-core-gray-500',
  copper:
    'bg-copper hover:bg-copper-1 focus-visible:ring-copper-3 active:bg-copper-3 disabled:bg-copper-disabled text-core-white disabled:text-core-gray-500',
  gray: 'bg-core-gray-600 hover:bg-core-gray-700 focus-visible:ring-core-gray-500 active:bg-core-gray-800 disabled:bg-core-gray-300 text-core-white disabled:text-core-gray-600',
  lightGray:
    'bg-core-gray-100 hover:bg-core-gray-300 focus-visible:ring-core-gray-300 active:bg-core-gray-400 disabled:bg-core-gray-400 text-core-gray-950 disabled:text-core-gray-600',
}

const secondary = {
  white:
    'border border-core-white text-core-white hover:bg-core-gray-800 focus-visible:ring-core-white active:bg-core-gray-800 active:text-core-gray-400 disabled:bg-core-gray-700 disabled:border-0 disabled:text-core-gray-500',
  black:
    'border border-core-gray-950 text-core-gray-950 hover:bg-core-gray-100 focus-visible:ring-core-gray-950 active:bg-core-gray-300 active:text-core-gray-700 disabled:bg-core-gray-300 disabled:border-0 disabled:text-core-gray-500',
  oxide:
    'border border-core-oxide text-core-oxide hover:bg-core-oxide-core hover:text-core-white focus-visible:ring-core-oxide-core active:text-core-gray-300 active:bg-core-oxide-core disabled:bg-core-gray-300 disabled:border-0 disabled:text-core-gray-500',
  copper:
    'border border-copper text-copper hover:bg-copper-20 focus-visible:ring-copper active:bg-copper-20 active:text-copper-1 disabled:bg-copper-disabled disabled:border-0 disabled:text-core-gray-500',
  gray: 'border border-core-gray-600 text-core-gray-600 hover:bg-core-gray-100 focus-visible:ring-core-gray-600 active:bg-core-gray-100 active:text-core-gray-800 disabled:bg-core-gray-100 disabled:border-0 disabled:text-core-gray-400',
}

const text = {
  white:
    'text-core-white border border-transparent hover:border-core-white focus-visible:ring-core-white active:text-core-gray-300 disabled:text-core-gray-700',
  black:
    'text-core-gray-950 border border-transparent hover:border-core-gray-950 focus-visible:ring-core-gray-950 active:text-core-gray-700 disabled:text-core-gray-400',
  oxide:
    'text-core-oxide border border-transparent hover:border-core-oxide focus-visible:ring-core-oxide active:text-core-oxide-core disabled:text-core-gray-700',
  copper:
    'text-copper border border-transparent hover:border-copper focus-visible:ring-copper active:text-copper-1 disabled:text-copper-disabled',
  gray: 'text-core-gray-600 border border-transparent hover:border-core-gray-600 focus-visible:ring-core-gray-600 active:text-core-gray-700 disabled:text-core-gray-400',
}

const link = {
  white:
    'text-core-white active:text-core-gray-300 disabled:text-core-gray-700',
  black:
    'text-core-gray-950 active:text-core-gray-700 disabled:text-core-gray-400',
  oxide:
    'text-core-oxide active:text-core-oxide-core disabled:text-core-gray-700',
  copper: 'text-copper active:text-copper-1 disabled:text-copper-disabled',
  gray: 'text-core-gray-600 active:text-core-gray-700 disabled:text-core-gray-400',
}

const config = {
  primary,
  secondary,
  text,
  link,
}

export default config
