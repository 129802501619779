function createHandler(el, setInView, options) {
  const { threshold = 0.5, unobserveAfterEntry = false } = options
  return (entries, instance) => {
    const isInView = entries[0].intersectionRatio > threshold
    setInView(isInView)

    if (isInView && unobserveAfterEntry) {
      instance.unobserve(el)
    }
  }
}

export default createHandler
