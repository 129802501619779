import client from './thinMintClient'

export const trackSegmentEvent = async (
  event: string,
  properties: { user_id?: string; anonymous_id: string }
) => {
  const context = {
    page: {
      url: window?.location?.href,
    },
    userAgent: navigator?.userAgent,
  }
  const { user_id, anonymous_id } = properties
  const params = {
    userId: user_id || anonymous_id, // userId required for TM
    anonymousId: anonymous_id,
    context,
    event,
    properties,
  }

  return client.post('/segment/track', {
    ...params,
    anonymous_id: params.anonymousId,
    user_id: params.userId,
  })
}
