import dynamic, { DynamicOptions } from 'next/dynamic'
import { report } from '@/utils'
import { AppFeatures } from '../../app-features'
import { Props } from './FadeInExperiment'

type FadeInExperimentProps = Props<keyof AppFeatures>

const DynamicFadeInExperiment = dynamic(
  import('./FadeInExperiment')
    .then((mod) => mod.FadeInExperiment)
    .catch((err) =>
      report.error(`Failed to load the FadeInExperiment!`, err)
    ) as DynamicOptions<FadeInExperimentProps>,
  { ssr: false }
)

export const FadeInExperiment = (props: FadeInExperimentProps) => (
  <DynamicFadeInExperiment {...props} />
)
