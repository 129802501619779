import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import createHandler from './createHandler'
import createObserver from './createObserver'

const hasWindow = typeof window !== 'undefined'

const InView = (
  {
    children,
    unobserveAfterEntry = false,
    observerOptions = {},
    className = '',
    inViewClassName = '',
    style = {},
    inViewStyle = {},
    onEnter,
    onExit = undefined,
  },
  ref
) => {
  // Set up state value to track when element is in view
  const [inViewport, setInViewport] = useState(hasWindow ? null : true)

  // Set up ref for element being observed
  const el = useRef()

  // // Set up ref for the observer
  const observer = useRef()

  // Set up ref to track first time element is observed in viewport
  // IntersectionObserver returns false on page load, so we can use this to
  // avoid running the callback passed for `onExit`
  const hasEntered = useRef()

  // Set up IntersectionObserver once DOM is loaded
  useEffect(() => {
    if (el.current && hasWindow) {
      // Set up IntersectionObserver options
      const resolvedObserverOptions = {
        rootMargin: '0px', // rootMargin must be specified in pixels or percent
        threshold: 0.5, // threhold is a number between 0 and 1; equal to proportion of element visible before triggering inViewport
        ...observerOptions,
      }

      // Create the handler function that gets called when the target
      // element intersects the window
      const handler = createHandler(el.current, setInViewport, {
        ...resolvedObserverOptions,
        unobserveAfterEntry,
      })

      // Stop current observer if it exists
      if (observer.current) {
        observer.current.disconnect()
      }

      // Create new observer and save on a ref
      observer.current = createObserver(
        handler,
        el.current,
        resolvedObserverOptions
      )
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [observerOptions, unobserveAfterEntry])

  // This allows the parent component to get access to the
  // InterSectionObserver instance
  useImperativeHandle(ref, () => ({
    getObserver: () => observer.current,
    getElement: () => el.current,
  }))

  // Handle triggering callbacks
  useEffect(() => {
    if (inViewport) {
      if (onEnter) {
        onEnter()
        hasEntered.current = true
      }
    } else if (!inViewport && hasEntered.current && onExit) {
      onExit()
    }
  }, [inViewport, onEnter, onExit])

  let resolvedClassName = className
  let resolvedStyle = { ...style }
  if (inViewport) {
    resolvedClassName = inViewClassName
      ? `${className} ${inViewClassName}`
      : className
    resolvedStyle = { ...style, ...inViewStyle }
  }

  return (
    <div ref={el} className={resolvedClassName} style={resolvedStyle}>
      {children}
    </div>
  )
}

export default React.forwardRef(InView)
