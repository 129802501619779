import React, { PropsWithChildren } from 'react'
import { AppFeatures } from '../app-features'
import { useExperiment } from '../hooks'
import { Props as VariationProps } from './ExperimentVariation'

// This borrows the implementation from Optmizely's Experiment component that loops through
// `children` to find the matching variation.
//  https://github.com/optimizely/react-sdk/blob/master/src/Experiment.tsx

export interface Props<T extends keyof AppFeatures> {
  experimentName: T
  defaultValue: AppFeatures[T]
}

export function Experiment<T extends keyof AppFeatures>({
  experimentName,
  defaultValue,
  children,
}: PropsWithChildren<Props<T>>) {
  const [variation, clientReady] = useExperiment(experimentName, defaultValue)

  if (!clientReady) {
    // Only block rendering while were waiting for the client
    return null
  }

  let defaultMatch: React.ReactElement<VariationProps> | null = null
  let variationMatch: React.ReactElement<VariationProps> | null = null

  // We use React.Children.forEach instead of React.Children.toArray().find()
  // here because toArray adds keys to all child elements and we do not want
  // to trigger an unmount/remount
  React.Children.forEach(
    // @ts-expect-error `children` type needs work
    children,
    (child: React.ReactElement<VariationProps>) => {
      if (!React.isValidElement(child)) {
        return
      }

      const { default: isDefault, variation: childVariation } = child.props

      if (!isDefault && childVariation === variation) {
        variationMatch = child
      }

      if (!isDefault && typeof childVariation !== typeof defaultValue) {
        // eslint-disable-next-line
        console.error(
          `Invalid variation "${childVariation}" used for experiment: "${experimentName}"`
        )
      }

      // Last child with default prop wins
      if (isDefault) {
        defaultMatch = child
      }
    }
  )

  let match: React.ReactElement<VariationProps> | null = null
  if (variationMatch) {
    match = variationMatch
  } else if (defaultMatch) {
    match = defaultMatch
  }
  return match
}
