import { RefObject, useEffect, useRef, useState } from 'react'

interface Options {
  observeOnce: boolean
}

const defaultOptions = { observeOnce: false }

function useOnScreen(ref: RefObject<Element>, opts: Options = defaultOptions) {
  const [isIntersecting, setIntersecting] = useState(false)
  const observerRef = useRef<IntersectionObserver>()
  const options = { ...defaultOptions, ...opts }

  useEffect(() => {
    if (!ref.current) return

    observerRef.current = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )

    observerRef.current.observe(ref.current)

    // remove observer as soon as component is unmounted
    return () => {
      observerRef.current?.disconnect()
    }
  }, [ref])

  useEffect(() => {
    if (isIntersecting && options.observeOnce) {
      observerRef.current?.disconnect()
    }
  }, [isIntersecting, options.observeOnce])

  return isIntersecting
}

export default useOnScreen
