import React, {
  useState,
  useEffect,
  useCallback,
  IframeHTMLAttributes,
} from 'react'
import classNames from 'classnames'
import useNonCallbackForwardedRef from '@/services/hooks/useNonCallbackForwardedRef'

export interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {
  fadeInOnLoad?: boolean
}

const Iframe = (
  { fadeInOnLoad = true, className, ...rest }: Props,
  forwardedRef: React.ForwardedRef<HTMLIFrameElement | null> | undefined
) => {
  const ref = useNonCallbackForwardedRef(forwardedRef)
  const [iframeLoaded, setIframeLoaded] = useState(!fadeInOnLoad)

  const handleIframeLoad = useCallback(() => {
    setIframeLoaded(true)
  }, [setIframeLoaded])

  useEffect(() => {
    const el = ref.current
    el?.addEventListener('load', handleIframeLoad, true)
    return () => {
      el?.removeEventListener('load', handleIframeLoad)
    }
  }, [ref, handleIframeLoad])

  return (
    <div
      className={classNames(className, 'transition-opacity duration-300', {
        'opacity-0': !iframeLoaded,
      })}
    >
      <iframe {...rest} ref={ref} title="iframe" className="h-full w-full" />
    </div>
  )
}

export default React.forwardRef(Iframe)
