import client from '../thinMintClient'
import { resolveIssuerDown } from './resolver'
import { ServerIssuer } from './types'

export const baseEndpoint = '/issuers'

export const getIssuers = () =>
  client
    .get<{ results: ServerIssuer[] }>(`${baseEndpoint}/`)
    .then((res) => res.data.results.map(resolveIssuerDown))

export const getIssuer = (issuerId: number) =>
  client
    .get<ServerIssuer>(`${baseEndpoint}/${issuerId}/`)
    .then((res) => resolveIssuerDown(res.data))
