import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import { ChevronDown } from '@/components/svg'
import useMeasure from '@/services/hooks/useMeasure'
import ButtonBase from '../ButtonBase'

interface Props {
  lines: number
  className?: string
  children: React.ReactNode
}

const ExpandableType: React.FC<Props> = ({ lines, children, className }) => {
  const [showingMore, setShowingMore] = useState(false)
  const [ref, rect] = useMeasure<HTMLDivElement>()

  const textOverflows = useMemo(() => {
    if (ref?.current && rect?.height) {
      return ref.current.scrollHeight > rect.height
    }
    return false
  }, [ref, rect])

  const style = useMemo(() => {
    if (!showingMore) {
      return {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
      } as const
    }
  }, [showingMore, lines])

  return (
    <div className={className}>
      <div className="relative flex flex-col">
        <div ref={ref} style={style}>
          {children}
        </div>

        {(showingMore || textOverflows) && (
          <div className="flex justify-center mt-1">
            <ButtonBase
              className={classNames({ 'rotate-180': showingMore })}
              onClick={() => setShowingMore(!showingMore)}
              aria-label={showingMore ? 'Show Less' : 'Show More'}
            >
              <ChevronDown className="h-6 w-6" />
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpandableType
