export const detectType = (cardNumber: string) => {
  // https://stackoverflow.com/questions/72768/how-do-you-detect-credit-card-type-based-on-number

  const visa = /^4[0-9]{0,}$/
  const masterCard = /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/
  const discover =
    /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/

  if (cardNumber.match(visa)) return 'Visa'
  if (cardNumber.match(masterCard)) return 'Mastercard'
  if (cardNumber.match(discover)) return 'Discover'

  return ''
}

export const detectTypeInitials = (cardNumber: string) => {
  const type = detectType(cardNumber)

  if (type === 'Visa') return 'VI'
  if (type === 'Mastercard') return 'MC'
  if (type === 'Discover') return 'DI'

  return ''
}
