import { useEffect, useState } from 'react'
import { ISiteAssetsFields } from 'src/types/generated/contentful'
import {
  getSiteAssets,
  resolveSiteAssets,
  SiteAssets,
  Domain,
  whiteLabelUtil,
} from '@/services/api/siteAssets'
import SiteAssetsContext from './SiteAssetsContext'

const SiteAssetsProvider: React.FC<{
  assets?: SiteAssets
  domain?: Domain
  children: React.ReactNode
}> = ({ assets, domain, children }) => {
  // if no domain, try looking it up from window
  const resolvedDomain =
    !domain && typeof window !== 'undefined'
      ? whiteLabelUtil.getDomainFromHostname(window.location.hostname)
      : domain

  const [siteAssets, setSiteAssets] = useState(
    resolveSiteAssets(assets || ({} as ISiteAssetsFields), resolvedDomain)
  )

  // not every page has getStaticProps, so we may need to fetch site assets
  useEffect(() => {
    async function fetchSiteAssets() {
      // TODO: should wrap in try/catch and determine what to do when this fails
      const res = await getSiteAssets(resolvedDomain)
      setSiteAssets(res)
    }

    if (assets) return

    fetchSiteAssets()
  }, [assets, resolvedDomain])

  return (
    <SiteAssetsContext.Provider value={siteAssets}>
      {children}
    </SiteAssetsContext.Provider>
  )
}

export default SiteAssetsProvider
