import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import { FormInput } from '../types'

export interface Props extends FormInput {
  type?: string
  format?: string
  onBlur?: () => void
}

export const maskChars = (str: string, char = '\u2022') =>
  str
    .split('')
    .map(() => char)
    .join('')

const FormSecretInput: React.FC<Props> = ({
  id,
  name,
  type,
  format = '',
  value = '',
  placeholder,
  onBlur,
  autoComplete,
  className,
}) => {
  const { setFieldValue } = useFormikContext()

  const onChange = useCallback(
    ({ target: { value: changedValue } }) => {
      let newValue

      // Add or remove characters based on changed value
      if (value.length > changedValue.length) {
        newValue = value.slice(0, changedValue.length)
      } else {
        newValue = `${value}${changedValue.slice(value.length)}`
      }

      // replace non-numeric chars if type is number
      if (type === 'number') {
        newValue = newValue.replace(/[^0-9]/g, '')
      }

      // apply max length if can be determined from format string
      if (format) {
        const maxLength = format.split('').filter((c) => c === '#').length // format is like '## ### ###'
        newValue = newValue.slice(0, maxLength)
      }

      setFieldValue(name, newValue)
    },
    [setFieldValue, name, value, type, format]
  )

  return (
    <input
      id={id}
      name={name}
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={maskChars(value)}
      autoComplete={autoComplete}
      className={className}
    />
  )
}

export default FormSecretInput
