import React from 'react'
import { Popover } from '@headlessui/react'
import { Close } from '@/components/svg'
import Type from '../Type'
import BasePopover, { Props as PopoverProps } from './Popover'

export interface Props extends Omit<PopoverProps, 'content'> {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setOpenCallback?: () => void
  text: string
  title: string
}

const InfoPopover = ({
  children,
  open,
  setOpen,
  setOpenCallback,
  text,
  title,
  ...rest
}: Props) => {
  return (
    <BasePopover
      {...rest}
      openOverride={open}
      content={
        <div className="relative p-3 text-sm font-light">
          <Popover.Button
            className="absolute right-3 top-3 w-4"
            onClick={(e) => {
              e.stopPropagation()
              setOpenCallback && setOpenCallback()
              setOpen(false)
            }}
          >
            <Close />
          </Popover.Button>
          <Type as="p" variant="title-xs" className="mb-3 mr-6 font-semibold">
            {title}
          </Type>

          <Type as="p" variant="paragraph-sm">
            {text}
          </Type>
        </div>
      }
    >
      <div
        onClick={async () => {
          setOpenCallback && setOpenCallback()
          setOpen(false)
        }}
      >
        {children}
      </div>
    </BasePopover>
  )
}

export default InfoPopover
