import React, { useState, ElementType } from 'react'
import { Button, ButtonBase, Text, CardModal } from '@/components/common'
import { extraConfig } from '@/services/api/siteAssets/resolver'

interface Props {
  domain: keyof typeof extraConfig
  children: React.ReactNode
  path?: string
  as?: ElementType
  className?: string
}

export const InterstitialLink: React.FC<Props> = ({
  domain,
  children,
  path = '',
  as,
  className,
  ...rest
}) => {
  const siteAssets = extraConfig[domain]
  const [modalIsOpen, setModalOpen] = useState(false)

  if (!siteAssets) return null

  const Btn = as || ButtonBase

  const isButtonEl = !as || as === 'button'

  const prompt =
    domain === 'invest.angel.com'
      ? `You are now leaving this website to enter ${siteAssets.title}, a FINRA regulated portal.`
      : `You are now leaving this website to go to ${siteAssets.title}`

  const url = `https://${domain}/${path}`

  return (
    <>
      <Btn
        type={isButtonEl ? 'button' : undefined}
        onClick={() => setModalOpen(true)}
        className={className}
        {...rest}
      >
        {children}
      </Btn>

      <CardModal isOpen={modalIsOpen} setIsOpen={setModalOpen}>
        <CardModal.Header>Leaving this site</CardModal.Header>
        <CardModal.Body>
          <Text as="p" preset="body.md">
            {prompt}
          </Text>
        </CardModal.Body>
        <CardModal.Footer className="flex flex-col gap-3 px-6 pb-6">
          <Button size="md" onClick={() => window.open(url, '_blank')}>
            Continue to {siteAssets.title}
          </Button>

          <Button color="gray" size="md" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        </CardModal.Footer>
      </CardModal>
    </>
  )
}

export default InterstitialLink
