import React from 'react'
import { BusyNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import useNotifications from '@/services/hooks/useNotifications'
import BusyNotification from './BusyNotification'

export const BusyNotificationWrap = () => {
  const { notifications } = useNotifications()
  const message = notifications.find((n) => n.type === 'busy') as Notification

  if (!message) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[9999]">
      <div
        className="absolute inset-0 flex items-center justify-center"
        style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
      >
        <BusyNotification {...message} />
      </div>
    </div>
  )
}

export default BusyNotificationWrap
