import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { UploadDocument } from '@/components/svg'
import { FILE_SIZE_LIMIT } from '@/services/formValidation'
import Button from '../Button'
import ErrorBox from '../ErrorBox'
import HeightTransitioner from '../HeightTransitioner'
import Text from '../Text/Text'
import { DropzoneFile as DropzoneFileProps } from '../form/types'
import DropzoneFile from './DropzoneFile'

export interface Props {
  name: string
  description?: string | JSX.Element
  accept?: string
  maxFiles?: number
  maxSize?: number
  files: DropzoneFileProps[]
  setFiles: (files: File[]) => void
  disabled?: boolean
  className?: string
}

interface Rejected {
  errors: { code: string }[]
}

const Dropzone: React.FC<Props> = ({
  name,
  description,
  accept = 'text/*,image/*,application/pdf',
  maxFiles = 10,
  maxSize = FILE_SIZE_LIMIT,
  files = [],
  setFiles,
  disabled = false,
  className,
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<Rejected[]>([])

  const onDrop = useCallback(
    (accepted, rejected) => {
      setFiles(accepted)
      setRejectedFiles(rejected)
    },
    [setFiles, setRejectedFiles]
  )

  const isDisabled = disabled || files.length >= maxFiles

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxFiles: maxFiles - files.length,
    maxSize,
    disabled: isDisabled,
  })

  const warningText = useMemo(() => {
    const len = rejectedFiles.length
    if (len === 0) return null

    const tooMany = rejectedFiles.some(({ errors }) =>
      errors.some(({ code }) => code === 'too-many-files')
    )
    if (tooMany) {
      return `You can only upload ${maxFiles} ${
        maxFiles === 1 ? 'file' : 'files'
      }`
    }

    const maxSizeStr = maxSize / 1024 / 1024
    const acceptStr = accept.split(',').join(', ')
    return `${len} ${
      len > 1 ? 'files' : 'file'
    } rejected. Max file size is ${maxSizeStr} MB. Allowed file types ${
      accept.split(',').length > 1 ? 'are' : 'is'
    } ${acceptStr}.`
  }, [rejectedFiles, maxFiles, maxSize, accept])

  return (
    <div className={classNames('rounded-lg bg-core-gray-100 p-6', className)}>
      {description}

      <HeightTransitioner>
        <ul className="pt-1">
          {files.map((file: DropzoneFileProps) => (
            <li key={file.name} className="max-w-sm py-[2px]">
              <DropzoneFile
                {...file}
                handleRemove={async () => {
                  setRejectedFiles([])
                  if (file.handleRemove) {
                    await file.handleRemove()
                  }
                }}
              />
            </li>
          ))}
        </ul>

        <div className="pt-1">
          <ErrorBox
            errorText={warningText}
            color="yellow"
            size="sm"
            className="max-w-sm"
          />
        </div>
      </HeightTransitioner>

      <div
        {...getRootProps()}
        className={classNames({
          'relative mt-3 rounded border-2 border-dashed border-gray-6 bg-white p-6':
            true,
          'pointer-events-none cursor-default opacity-50': isDisabled,
        })}
      >
        <input id={name} name={name} {...getInputProps()} />

        <div className="mx-auto flex max-w-[324px] flex-col items-center text-center">
          <UploadDocument className="-mt-2 mb-4 h-24 w-24 text-oxide" />
          <Text as="p" preset="body.lg">
            Drag your documents or files here to start uploading
          </Text>
          <Text as="p" preset="body.lg" className="mb-3 mt-2 font-light">
            OR
          </Text>
          <Button disabled={isDisabled} type="button" size="sm">
            Browse files
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Dropzone
