import { createContext } from 'react'
import { User } from '@/services/api/user'
import { getDefaultContext } from './utils'

export type AuthModalView =
  | 'register'
  | 'login'
  | 'resetPassword'
  | 'verifyAccount'
  | 'eiVerifyAccount'
  | 'verifyEmail'

export interface Context {
  user?: User | null
  startAuth: (statePath?: string) => void
  openModal: (
    view?: AuthModalView,
    redirect?: string,
    dismissable?: boolean
  ) => void
  closeModal: () => void
  modalIsOpen: boolean
  modalView: AuthModalView
  setModalView: (view: AuthModalView) => void
  persistUser: (
    userId: string,
    token: string,
    refreshToken: string | null,
    redirect: string | null
  ) => void
  clearUser: () => void
  redirect: string | null
  dismissable: boolean
}

const defaultContext = getDefaultContext()

const AuthContext = createContext<Context>(defaultContext as Context)

export default AuthContext
