import { useState, useEffect } from 'react'

const useHydration = (options = { waitForAnimationFrame: false }) => {
  const [isHydrated, setHydrated] = useState(false)
  const [frameExpired, setFrameExpired] = useState(false)
  useEffect(() => setHydrated(true), [])
  useEffect(() => {
    window.requestAnimationFrame(() => {
      setFrameExpired(true)
    })
  }, [])

  if (options.waitForAnimationFrame) {
    return { isHydrated: frameExpired }
  }

  return { isHydrated }
}

export default useHydration
