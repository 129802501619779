import React from 'react'
import classNames from 'classnames'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  styled?: boolean
  children?: React.ReactNode
  className?: string
}

const ButtonBase: React.FC<Props> = ({
  className = '',
  styled = false,
  disabled,
  children,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classNames(
        {
          'cursor-pointer border-0 outline-none active:opacity-75': !styled,
          'pointer-events-none': disabled,
        },
        className
      )}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default ButtonBase
