export { default as AngelFundingLogo } from './angel-funding-logo'
export { default as Close } from './close.svg'
export { default as CloseAlt } from './close-alt.svg'
export { default as HIWCreate } from './hiw-create.svg'
export { default as HIWInvest } from './hiw-invest.svg'
export { default as HIWReturn } from './hiw-return.svg'
export { MdNotificationsActive as NotificationsActive } from 'react-icons/md'
export {
  FaHandPointUp as HandPointUp,
  FaHandHoldingUsd as HandHoldingUsd,
} from 'react-icons/fa'
export {
  BsClockHistory as Clock,
  BsQuestionCircleFill as Question,
} from 'react-icons/bs'
export { default as Fire } from './fire-outline.svg'
export { RiGroupLine as People } from 'react-icons/ri'
export { VscWarning as Warning } from 'react-icons/vsc'
export { default as Checkmark } from './checkmark.svg'
export { default as Plus } from './plus.svg'
export { default as PlusCircle } from './plus-circle.svg'
export { default as Minus } from './minus.svg'
export { default as MinusCircle } from './minus-circle.svg'
export { default as Exclamation } from './exclamation.svg'
export { default as ConfirmationIcon } from './confirmation.svg'
export { default as FacebookCircle } from './facebook-circle.svg'
export { default as TwitterCircle } from './twitter-circle.svg'
export { default as EmailCircle } from './email-circle.svg'
export { default as Document } from './document.svg'
export { default as TextDocument } from './text-document.svg'
export { default as UploadDocument } from './upload-document.svg'
export { default as TriangleDown } from './triangle-down.svg'
export { default as Spinner } from './spinner.svg'
export { default as Lock } from './lock.svg'
export { default as Info } from './info.svg'
export { default as Eye } from './eye.svg'
export { default as EyeSlash } from './eye-slash.svg'
export { default as RadioOption } from './radio-option.svg'
export { default as BackArrow } from './back-arrow.svg'
export { default as QuestionOutline } from './question-mark.svg'
export { default as Dashboard } from './dashboard.svg'
export { default as Reports } from './reports.svg'
export { default as Messages } from './messages.svg'
export { default as Profile } from './profile.svg'
export { default as Bell } from './bell.svg'
export { default as Menu } from './menu.svg'
export { default as ChevronRight } from './chevron-right.svg'
export { default as Users } from './users.svg'
export { default as ArrowSquareOut } from './arrow-square-out'
export { default as ChevronDown } from './chevron-down.svg'
export { default as Bank } from './bank.svg'
export { default as CreditCard } from './credit-card.svg'
export { default as ECheck } from './e-check.svg'
export { default as WireTransfer } from './wire-transfer.svg'
export { default as Pencil } from './pencil.svg'
export { default as Visa } from './visa.svg'
export { default as Mastercard } from './mastercard.svg'
export { default as Discover } from './discover.svg'
export { default as CheckDiagram } from './check-diagram.svg'
export { default as PDF } from './pdf.svg'
export { SiGoogle as Google } from 'react-icons/si'
export { default as AngelRoundIcon } from './angel-round-icon.svg'
export { default as ChatSubmitButton } from './chat-submit-button'
export { default as CheckCircle } from './check-circle.svg'
export { default as CheckCircleFill } from './check-circle-fill.svg'
export { default as Envelope } from './envelope.svg'
export { default as Trash } from './trash.svg'
export { default as Bold } from './bold.svg'
export { default as Italic } from './italic.svg'
export { default as BulletList } from './bullet-list.svg'
export { default as OrderedList } from './ordered-list.svg'
export { default as Link } from './link.svg'
export { default as CaretDown } from './caret-down.svg'
export { default as Guild } from './guild.svg'
export { default as ShoppingCart } from './shopping-cart.svg'
export { default as Secure } from './secure.svg'
export { default as Verified } from './verified.svg'
export { default as Filter } from './filter.svg'
export { default as Search } from './search.svg'
export { default as Play } from './play.svg'
export { default as Identification } from './indentification.svg'
export { default as Initiate } from './initiate.svg'
export { default as Transfer } from './transfer.svg'
export { default as ThumbsUp } from './thumbs-up.svg'
export { default as Institution } from './institution.svg'
export { default as ArrowRight } from './arrow-right.svg'
export { default as ArrowUp } from './arrow-up.svg'
