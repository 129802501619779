import { useState, useEffect } from 'react'
import { getTextWidth } from '@/utils'

const useTextWidth = ({ text, fontName = 'WhitneySsm', fontSize = 16 }) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const getWidthWhenFontsReady = async () => {
      if (!text || typeof text !== 'string' || text.length === 0) {
        setWidth(0)
        return
      }

      await document.fonts.ready
      const textWidth = getTextWidth(
        text,
        `${fontSize}px / ${fontSize}px ${fontName}`
      )
      setWidth(textWidth)
    }

    getWidthWhenFontsReady()
  }, [text, fontName, fontSize])

  return width
}

export default useTextWidth
