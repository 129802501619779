// Note: this is rather hacky, but we need to orient the portal in different
// places within the DOM to get all the headless UI components playing nice.
// Without a portal, the options are frequently covered by other elements.
const getPopoverPortalEl = (targetRef: Element | null | undefined) => {
  const portalEl =
    targetRef?.closest('[aria-modal=true]') || // bind to closest modal
    targetRef?.closest('form') || // then the closest form if no modal
    document?.body // fallback to body
  return portalEl
}

export default getPopoverPortalEl
