import { useEffect, useState } from 'react'
import { getUser, User } from '@/services/api/user'
import useApi from '../useApiV2'

const useUser = (userId?: string | null) => {
  const { data, error, ...rest } = useApi<User | null>({
    key: userId || false, // request won't run if userId is falsy
    request: () => getUser(userId as string),
    config: { errorRetryCount: 2 },
  })

  const [retryCount, setRetryCount] = useState(0)

  useEffect(() => {
    if (!error) {
      setRetryCount(0)
    } else {
      setRetryCount((cur) => cur + 1)
    }
  }, [error])

  // If userId is undefined, then return undefined to indicate that
  // we don't know if we have the user. E.g., we may be in limbo restoring
  // the user from localStorage.
  if (typeof userId === 'undefined') {
    return {
      ...rest,
      user: undefined,
      isLoading: false,
      error: null,
      isError: null,
      retryCount,
    }
  }

  // If userId is null, return null to indicate that we know we don't have a user
  if (userId === null) {
    return {
      ...rest,
      user: null,
      isLoading: false,
      error: null,
      isError: null,
      retryCount,
    }
  }

  // Else userId is truthy, so return the result of the request
  return { user: data, error, retryCount, ...rest }
}

export default useUser
