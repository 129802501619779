import { getGuildMembershipCount, keyGen } from '@/services/api/hydra'
import useApi from '@/services/hooks/useApiV2'

export const useGuildLiveCount = () => {
  return useApi({
    key: keyGen.getGuildMembershipCount,
    request: getGuildMembershipCount,
    config: {
      refreshInterval: 30 * 1000, // 30 seconds
    },
  })
}
