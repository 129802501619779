import client from '../thinMintClient'
import { resolvePartyDown, resolvePartyUp } from './resolver'
import { ServerParty, PartyForm } from './types'

export const baseEndpoint = '/parties'

export const getAuthenticatedParty = () =>
  client
    .get<{ results: ServerParty[] }>(`${baseEndpoint}/`)
    .then((res) => res.data.results.map(resolvePartyDown)[0])

export const getPartyWithAuthToken = (token: string) =>
  client
    .get<{ results: ServerParty[] }>(`${baseEndpoint}/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data.results.map(resolvePartyDown)[0])

export const createParty = (party: PartyForm) =>
  client
    .post(`${baseEndpoint}/`, resolvePartyUp(party))
    .then((res: { data: ServerParty }) => resolvePartyDown(res.data))

export const updateParty = (partyId: number, party: PartyForm) =>
  client
    .put(`${baseEndpoint}/${partyId}/`, resolvePartyUp(party))
    .then((res: { data: ServerParty }) => resolvePartyDown(res.data))

export const updatePartyWithAuthToken = (
  partyId: number,
  party: PartyForm,
  token: string
) =>
  client
    .put(`${baseEndpoint}/${partyId}/`, resolvePartyUp(party), {
      headers: {
        Authorization: token,
      },
    })
    .then((res: { data: ServerParty }) => resolvePartyDown(res.data))
