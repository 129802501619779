// NextJS router.query is an empty object on first render, so this helper func
// can be useful if we need a query param right away
// getNextRouterQueryParam('desiredQueryParamKey', routerObject)

const getNextRouterQueryParam = (key, { asPath, query }) => {
  if (query[key]) {
    return query[key]
  }

  const match = asPath.match(new RegExp(`[&?]${key}=(.*?)(&|$)`))
  if (!match) return undefined
  return decodeURIComponent(match[1])
}

export default getNextRouterQueryParam
