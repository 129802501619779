import client from '../thinMintClient'
import {
  resolveCampaignDown,
  resolveUserFollowingCampaignDown,
} from './resolver'
import { ServerCampaign, ServerUserFollowingCampaign } from './types'

export const baseEndpoint = '/campaign'

export const getAllCampaigns = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: ServerCampaign[] }) =>
      res.data.map(resolveCampaignDown)
    )

export const getCampaign = (slug: string) =>
  client
    .get(`${baseEndpoint}/${slug}/`)
    .then((res: { data: ServerCampaign }) => resolveCampaignDown(res.data))

export const getUserInvestedCampaigns = () =>
  client
    .get(`${baseEndpoint}/`, { params: { user_invested_in: true } })
    .then((res: { data: ServerCampaign[] }) =>
      res.data.map(resolveCampaignDown)
    )

export const getIssuerCampaigns = (issuerId: number) =>
  client
    .get(`${baseEndpoint}/?issuer=${issuerId}`)
    .then((res: { data: ServerCampaign[] }) =>
      res.data.map(resolveCampaignDown)
    )

export const getUserFollowingCampaign = (slug: string) =>
  client
    .get(`/user-following-campaign/?campaign_slug=${slug}`)
    .then((res: { data: ServerUserFollowingCampaign }) =>
      resolveUserFollowingCampaignDown(res.data)
    )

export const userFollowCampaign = (slug: string) =>
  client
    .post(`/user-following-campaign/`, {
      campaign: slug,
      following: true,
    })
    .then((res: { data: ServerUserFollowingCampaign }) =>
      resolveUserFollowingCampaignDown(res.data)
    )

export const userUnfollowCampaign = (slug: string) =>
  client
    .post(`/user-following-campaign/`, {
      campaign: slug,
      following: false,
    })
    .then((res: { data: ServerUserFollowingCampaign }) =>
      resolveUserFollowingCampaignDown(res.data)
    )
