import React from 'react'
import dynamic from 'next/dynamic'

interface Props {
  children: React.ReactNode
}

const Component: React.FC<Props> = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
)

const NoSsr = dynamic(() => Promise.resolve(Component), {
  ssr: false,
})

export default NoSsr
