import { casingUtil } from '@/utils'
import { Issuer, ServerIssuer } from './types'

export const resolveIssuerDown = ({
  metabase_dashboards,
  ...rest
}: ServerIssuer) => {
  return {
    ...casingUtil.snakeToCamel(rest),
    metabaseDashboards: metabase_dashboards.map((item) => {
      const [id, name] = Object.entries(item)[0]
      return { id, name }
    }),
  } as Issuer
}
