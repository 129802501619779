const AngelFundingLogo = ({
  className = '',
  fill1 = '#231f20',
  fill2 = '010101',
}) => (
  <svg
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 272.1 92.78"
    className={className}
  >
    <g data-name="Layer 1">
      <g fill={fill1}>
        <path d="M69.22 71.89c0-.3.24-.57.57-.57h12.3c.33 0 .57.27.57.57v2.48c0 .3-.24.57-.57.57h-8.95v5.68h7.47c.3 0 .57.27.57.57v2.48c0 .3-.27.57-.57.57h-7.47v7.65c0 .3-.27.57-.57.57h-2.78a.57.57 0 0 1-.57-.57V71.88ZM85.96 71.89c0-.3.27-.57.57-.57h2.87c.33 0 .57.27.57.57v12.36c0 2.57 1.75 4.59 4.38 4.59s4.41-2.03 4.41-4.56V71.89c0-.3.24-.57.57-.57h2.87c.3 0 .57.27.57.57v12.57c0 4.59-3.66 8.31-8.43 8.31s-8.4-3.72-8.4-8.31V71.89ZM107.99 71.56c0-.3.27-.54.57-.54h.76l12.57 13.36h.03V71.9c0-.3.24-.57.57-.57h2.78c.3 0 .57.27.57.57v20.34c0 .3-.27.54-.57.54h-.73l-12.63-13.87h-.03v13c0 .3-.24.57-.57.57h-2.75a.59.59 0 0 1-.57-.57V71.57ZM131.35 71.89c0-.3.24-.57.54-.57h7.16c5.83 0 10.61 4.75 10.61 10.55s-4.78 10.61-10.61 10.61h-7.16c-.3 0-.54-.27-.54-.57V71.9Zm7.34 16.9c3.93 0 6.8-2.96 6.8-6.92s-2.87-6.89-6.8-6.89h-3.45v13.81h3.45ZM153.65 71.89c0-.3.27-.57.57-.57h2.81c.3 0 .57.27.57.57V91.9c0 .3-.27.57-.57.57h-2.81a.59.59 0 0 1-.57-.57V71.89ZM163.11 71.56c0-.3.27-.54.57-.54h.76l12.57 13.36h.03V71.9c0-.3.24-.57.57-.57h2.78c.3 0 .57.27.57.57v20.34c0 .3-.27.54-.57.54h-.73l-12.63-13.87H167v13c0 .3-.24.57-.57.57h-2.75a.59.59 0 0 1-.57-.57V71.57ZM195.8 71.01c2.72 0 5.32 1.06 7.38 2.81.24.24.27.6.03.85l-1.87 1.96c-.24.24-.51.24-.78 0-1.3-1.15-2.9-1.75-4.57-1.75-3.78 0-6.77 3.23-6.77 7.01s2.99 6.92 6.8 6.92c1.69 0 2.99-.51 3.6-.76v-3.78h-4.23c-.33 0-.57-.24-.57-.54v-2.39c0-.33.24-.57.57-.57h7.62c.3 0 .54.27.54.57v8.95c0 .24-.12.39-.24.48 0 0-3.11 1.96-7.5 1.96-6.01 0-10.85-4.78-10.85-10.82S189.8 71 195.81 71Z" />
      </g>
      <g data-name="Angel Logo">
        <g data-name="Path 1">
          <g data-name="Group 1" fill={fill2}>
            <path
              data-name="Path 11"
              d="m270.73 54.92-20.07-4.29V9.76c0-.81-.58-1.5-1.37-1.64l-8.76-1.19c-.7-.05-1.31.47-1.36 1.17v49.92c.04.77.61 1.42 1.37 1.55l30.2 6.2c.66.09 1.26-.36 1.36-1.02 0-.05.01-.11.01-.16v-8.02c-.11-.76-.64-1.39-1.37-1.64M219.19 4.75l-30.47-2.46c-.75-.06-1.4.51-1.46 1.25v48.82c.05.78.68 1.41 1.46 1.46l30.47 2.46c.7.05 1.3-.47 1.36-1.16v-8.13c-.04-.77-.6-1.42-1.37-1.55l-20.44-1.74V33.66l16.79 1.55c.67.08 1.28-.39 1.36-1.06 0-.07.01-.15 0-.22V25.9c-.04-.77-.6-1.42-1.37-1.55l-16.79-1.46v-9.12l20.44 1.83c.7.05 1.31-.47 1.36-1.17V6.3c-.04-.77-.6-1.42-1.37-1.55m-52.17 17.42h-18.52c-.72-.08-1.37.45-1.45 1.17v7.95c0 .76.61 1.37 1.37 1.37h.09c1.46 0 6.75 0 8.39.09v7.48c-2.11.72-4.34 1.06-6.57 1-7.9.17-14.44-6.1-14.6-13.99v-.88c0-8.03 5.56-14.87 14.5-14.69 3.6-.04 7.07 1.33 9.67 3.83.61.41 1.4.41 2.01 0l5.74-6.02c.53-.47.58-1.29.11-1.83-.06-.07-.13-.13-.2-.18A29.14 29.14 0 0 0 149.49 0C135-.12 123.15 11.54 123.03 26.03v.42c.07 14.58 11.88 26.38 26.46 26.46a38.35 38.35 0 0 0 18.34-4.29c.34-.26.54-.67.55-1.1v-23.9c-.01-.77-.6-1.4-1.37-1.46M107.07 1.01l-8.85.27c-.81.06-1.45.74-1.46 1.55v26.18L68.48 3.47c-1.64-1.46-4.38-1-4.38.64v49.53c.04.74.67 1.32 1.41 1.28h.05l8.76-.64c.79-.07 1.39-.75 1.37-1.55V25.28h.09l29.1 27.73c.73.64 3.56.64 3.56-1.1V2.37c.01-.74-.58-1.35-1.32-1.37h-.05"
            />
            <path
              data-name="Path 12"
              d="M14.16 62.55c.25-.09.45-.28.56-.52l12.86-28.38.19-.24.19.24 5.92 13.51-8.46 1.7c-1.11.22-1.04 1.45.05 1.99l13.66 6.86c.42.22.9.31 1.37.26l9.17-1.16c.77-.16 1.58-.64 1.6-1.43 0-.26-.05-.53-.16-.77L29.64 8.58c-.52-.48-1.16-.8-1.86-.93-.74-.03-1.44.32-1.86.93L.16 63.69c-.11.23-.16.47-.16.73 0 .81.64 1.47 1.45 1.47h.14c.4 0 12.56-3.34 12.56-3.34"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AngelFundingLogo
