import axios from 'axios'
import { ICampaignAssets } from 'src/types/generated/contentful'
import { resolveCampaignAssetsDown } from './resolver'

export const baseEndpoint = '/api/campaign-assets'

export const getCampaignAssets = (slug: string | null) =>
  axios
    .get<{ campaignAssets: ICampaignAssets }>(`${baseEndpoint}?slug=${slug}`)
    .then((res) => resolveCampaignAssetsDown(res.data))
