import { Entry } from 'contentful'
import { Content } from '@/services/contentful/types'

type ContentFields<T extends Entry<T['fields']>> = {
  [K in keyof T['fields']]: T['fields'][K]
}

export type MappedContent<T extends Entry<T['fields']>> = ContentFields<T> & {
  id: string
  contentType: string
}

export function getAllByContentType(content: Content[], contentType: string) {
  return content.filter(
    (entry: Content) => entry.sys.contentType.sys.id === contentType
  )
}

export function getByContentType(content: Content[], contentType: string) {
  return content.find(
    (entry: Content) => entry.sys.contentType.sys.id === contentType
  )
}

export function mapContent<T extends Entry<T['fields']>>(
  content: T | undefined
): MappedContent<T> | undefined {
  if (!content) return undefined

  return {
    id: content.sys.id,
    contentType: content.sys?.contentType?.sys?.id,
    ...content.fields,
  }
}

export function mapContentRecursive<T extends Entry<T['fields']>>(
  content: Content | undefined
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): Content | MappedContent<T> | null {
  if (!content) return null // JSON serialization will error if undefined...

  // if fields, content needs to be mapped
  if (content.fields) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {
      id: content.sys.id,
      contentType: content.sys.contentType?.sys?.id || '',
      ...Object.entries(content.fields).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: Array.isArray(val)
            ? val.map(mapContentRecursive)
            : mapContentRecursive(val),
        }
      }, {}),
    }
  }

  // if no fields, return as-is
  return content
}

// This can be used to filter out empty values from a list in such a way
// that typescript accepts the resulting list to have only values of the T arg
export function filterEmpty<T>(value: T | null | undefined): value is T {
  return !!value
}
