import React from 'react'
import ReactDOM from 'react-dom'
import useHydration from '@/services/hooks/useHydration'
import { getPortalEl } from '@/utils'

export interface Props {
  popoverRef?: Element | null
  children: React.ReactNode
}

const Portal: React.FC<Props> = ({ popoverRef, children }) => {
  const { isHydrated } = useHydration()

  if (!isHydrated) return null

  return ReactDOM.createPortal(children, getPortalEl(popoverRef))
}

export default Portal
