export const downloadFile = (
  file: Blob,
  filename: string,
  extension: string
) => {
  const href = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = href
  link.setAttribute('download', `${filename}.${extension}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export default downloadFile
