export const resolveBirthday = (birthday) => {
  if (birthday) {
    const [year, month, day] = birthday.split('-')
    return `${month}-${day}-${year}`
  }

  return birthday
}

export const resolveBirthdayPayload = (birthday) => {
  if (birthday) {
    const [month, day, year] = birthday.split('-')
    return `${year}-${month}-${day}`
  }

  return birthday
}

export const resolvePhoneNumber = (phoneNumber) => {
  // if no value return empty string
  if (!phoneNumber) return ''

  // if there's no country code, cast to US
  if (!phoneNumber.includes('+')) {
    return `+1${phoneNumber}`
  }

  return phoneNumber
}

export const resolveFilePayload = (file) => {
  const fileObj = Array.isArray(file) ? file[0] : file
  const form = new FormData()
  form.append('file', fileObj, fileObj.name)
  return form
}
