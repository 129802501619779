export const detectDevice = () => {
  if (typeof window === 'undefined') return 'not available'

  const ua = window.navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

type RNWebviewWindow = typeof window & {
  ReactNativeWebView: Record<string, unknown>
}

export const isRNWebview = () => {
  if (typeof window === 'undefined') return false
  return !!(window as RNWebviewWindow).ReactNativeWebView
}
