const config: { [key: string]: string } = {
  'heading.3xl': 'text-4xl leading-tight tracking-tightest',
  'heading.2xl': 'text-3xl leading-normal tracking-tightest',
  'heading.xl': 'text-2xl font-medium leading-normal tracking-tightest',
  'heading.lg': 'text-xl font-semibold leading-normal tracking-tighter',
  'heading.md': 'text-lg font-semibold leading-normal tracking-tight',
  'heading.sm': 'text-base font-bold leading-normal tracking-none',
  'heading.xs': 'text-sm font-bold leading-loose tracking-none',
  'heading.eyebrow':
    'text-xs font-medium leading-normal tracking-wide uppercase',
  'body.lg': 'text-base leading-relaxed tracking-none',
  'body.md': 'text-sm leading-relaxed tracking-none',
  'body.sm': 'text-xs leading-relaxed tracking-none',
  'body.xs': 'text-2xs leading-relaxed tracking-none',
  custom: '',
}

export const prefixes = ['sm', 'md', 'lg', 'xl', '2xl']

// This will extend the config with variants for each prefix,
// e.g., sm:heading.lg, sm:body.lg, etc
const withPrefixes = prefixes.reduce(
  (acc, prefix) => {
    Object.entries(config)
      .filter(([key]) => key !== 'custom')
      .forEach(([key, val]) => {
        acc = { ...acc, [`${prefix}:${key}`]: val }
      })
    return acc
  },
  { ...config }
)

// This is to get an array of all variations of classNames. Currently used
// for the Tailwind compiler safelist.
const uniques: string[] = Object.values(withPrefixes)
  .filter((val) => val !== '')
  .map((val) => val.split(' '))
  .flat()
  .reduce((acc: string[], cur: string) => {
    return acc.includes(cur) ? acc : [...acc, cur]
  }, [])

export const getPrefixedUniques: string[] = prefixes.reduce(
  (acc: string[], prefix: string) => [
    ...acc,
    ...uniques.map((val) => `${prefix}:${val}`),
  ],
  uniques
)

// Log out to the console and then paste in the tailwind config
// console.log(JSON.stringify(getPrefixedUniques))

export default withPrefixes
