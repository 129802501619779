module.exports = [
  'text-4xl',
  'leading-tight',
  'tracking-tightest',
  'text-3xl',
  'leading-normal',
  'text-2xl',
  'font-medium',
  'text-xl',
  'font-semibold',
  'tracking-tighter',
  'text-lg',
  'tracking-tight',
  'text-base',
  'font-bold',
  'tracking-none',
  'text-sm',
  'leading-loose',
  'text-xs',
  'tracking-wide',
  'uppercase',
  'leading-relaxed',
  'text-2xs',
  'sm:text-4xl',
  'sm:leading-tight',
  'sm:tracking-tightest',
  'sm:text-3xl',
  'sm:leading-normal',
  'sm:text-2xl',
  'sm:font-medium',
  'sm:text-xl',
  'sm:font-semibold',
  'sm:tracking-tighter',
  'sm:text-lg',
  'sm:tracking-tight',
  'sm:text-base',
  'sm:font-bold',
  'sm:tracking-none',
  'sm:text-sm',
  'sm:leading-loose',
  'sm:text-xs',
  'sm:tracking-wide',
  'sm:uppercase',
  'sm:leading-relaxed',
  'sm:text-2xs',
  'md:text-4xl',
  'md:leading-tight',
  'md:tracking-tightest',
  'md:text-3xl',
  'md:leading-normal',
  'md:text-2xl',
  'md:font-medium',
  'md:text-xl',
  'md:font-semibold',
  'md:tracking-tighter',
  'md:text-lg',
  'md:tracking-tight',
  'md:text-base',
  'md:font-bold',
  'md:tracking-none',
  'md:text-sm',
  'md:leading-loose',
  'md:text-xs',
  'md:tracking-wide',
  'md:uppercase',
  'md:leading-relaxed',
  'md:text-2xs',
  'lg:text-4xl',
  'lg:leading-tight',
  'lg:tracking-tightest',
  'lg:text-3xl',
  'lg:leading-normal',
  'lg:text-2xl',
  'lg:font-medium',
  'lg:text-xl',
  'lg:font-semibold',
  'lg:tracking-tighter',
  'lg:text-lg',
  'lg:tracking-tight',
  'lg:text-base',
  'lg:font-bold',
  'lg:tracking-none',
  'lg:text-sm',
  'lg:leading-loose',
  'lg:text-xs',
  'lg:tracking-wide',
  'lg:uppercase',
  'lg:leading-relaxed',
  'lg:text-2xs',
  'xl:text-4xl',
  'xl:leading-tight',
  'xl:tracking-tightest',
  'xl:text-3xl',
  'xl:leading-normal',
  'xl:text-2xl',
  'xl:font-medium',
  'xl:text-xl',
  'xl:font-semibold',
  'xl:tracking-tighter',
  'xl:text-lg',
  'xl:tracking-tight',
  'xl:text-base',
  'xl:font-bold',
  'xl:tracking-none',
  'xl:text-sm',
  'xl:leading-loose',
  'xl:text-xs',
  'xl:tracking-wide',
  'xl:uppercase',
  'xl:leading-relaxed',
  'xl:text-2xs',
  '2xl:text-4xl',
  '2xl:leading-tight',
  '2xl:tracking-tightest',
  '2xl:text-3xl',
  '2xl:leading-normal',
  '2xl:text-2xl',
  '2xl:font-medium',
  '2xl:text-xl',
  '2xl:font-semibold',
  '2xl:tracking-tighter',
  '2xl:text-lg',
  '2xl:tracking-tight',
  '2xl:text-base',
  '2xl:font-bold',
  '2xl:tracking-none',
  '2xl:text-sm',
  '2xl:leading-loose',
  '2xl:text-xs',
  '2xl:tracking-wide',
  '2xl:uppercase',
  '2xl:leading-relaxed',
  '2xl:text-2xs',
]
