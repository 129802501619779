interface Props {
  stroke?: string
}

const ArrowSquareOut: React.FC<Props> = ({ stroke = '#fff' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 6.25V2.5H9.75M8.999 7.002l4.5-4.5M11.5 9v4a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5h4"
        stroke={stroke}
        strokeWidth="1.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowSquareOut
