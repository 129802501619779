import { createContext, useContext } from 'react'

export interface Context {
  expanded: number
  setExpanded: (index: number) => void
  speed: 'slow' | 'normal' | 'fast'
}

const AccordionContext = createContext<Context>({
  expanded: -1,
  setExpanded: () => null,
  speed: 'normal',
})

export const useAccordionContext = () => useContext(AccordionContext)

export default AccordionContext
