import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { Popover } from '@/components/common'
import { Bell } from '@/components/svg'
import useNotifications from '@/services/hooks/useNotifications'
import { BellNotificationList } from './BellNotificationList'

export const BellNotificationPopover = () => {
  const router = useRouter()
  const disabled = useMemo(() => router.asPath.includes('/invest/'), [router])

  const { notifications } = useNotifications()

  const hasNotifications = useMemo(
    () => notifications.filter((n) => n.type === 'bell' && !n.seen).length > 0,
    [notifications]
  )

  if (disabled) return null

  return (
    <Popover
      content={<BellNotificationList className="ml-10 w-[280px] p-3 pl-0" />}
      placement="bottom-end"
      className="mr-2"
      label={
        hasNotifications ? 'View unseen notifications' : 'View notifications'
      }
      theme="light"
    >
      <div className="relative">
        <Bell className="h-7 w-7" />
        {hasNotifications && (
          <div className="absolute right-[3px] top-[3px] h-2 w-2 rounded-full bg-oxide-3" />
        )}
      </div>
    </Popover>
  )
}

export default BellNotificationPopover
