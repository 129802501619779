export interface FormatMoneyOptions {
  locale?: string
  currency?: string
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  isCents?: boolean
  // zeroFractionDigits is a shortcut for max/minFractionDigits = 0
  zeroFractionDigits?: boolean
}

const formatMoney = (
  num: number | string,
  options: FormatMoneyOptions = {}
) => {
  const isNonZeroFalsy = !num && num !== 0
  if (isNonZeroFalsy || isNaN(Number(num))) return ''

  const {
    locale = 'en-US',
    currency = 'USD',
    isCents = true,
    zeroFractionDigits = false,
  } = options
  let { maximumFractionDigits = 2, minimumFractionDigits = 2 } = options

  if (zeroFractionDigits) {
    maximumFractionDigits = 0
    minimumFractionDigits = 0
  }

  const amount = isCents ? Number(num) / 100 : Number(num)

  return amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  })
}

export default formatMoney
